import React from "react";
import {connect} from "react-redux";

import {EvseMeterValuesContextStyled, EvseMeterValuesStyled} from "./EvseMeterValues";

const EvseMeterValuesExtend = ({stationId, number, data}) => {
    const meterValues = data?.data_transfer?.driveenergy?.extendedMeterValues?.[`connector_${number}`];

    if (!meterValues) {
        return null;
    }

    return (
        <EvseMeterValuesStyled>
            <EvseMeterValuesContextStyled>Дополнительные</EvseMeterValuesContextStyled>
            {Object.entries(meterValues).map(([key, value]) => (
              <div key={`${stationId}_extendedMeterValues_connector${number}_${key}`}>
                  {key}: {value}
                  <br />
              </div>
            ))}
        </EvseMeterValuesStyled>
    )
}

const mapStateToProps = ({station}) => ({
    data: station.data,
});

export default connect(mapStateToProps)(EvseMeterValuesExtend);
