import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import LoadingSpinner from '../../common/LoadingSpinner';
import {ErrorAlert} from '../../common/Alerts';
import Form, {Title, UploadField} from '../../common/Form';
import {Close} from '../../common/Modal';
import Button from '../../common/Button';

class AddPhotoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        file: {value: null, valid: false},
      },
      photoIsUploading: false,
    };
  }

  setFieldData = (name, value) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: {value, valid: this.fieldIsValid(name, value)},
      },
    });
  };

  fieldIsValid = (name, value) => {
    switch (name) {
      case 'file':
        if (!value) {
          return false;
        }

        const nameParts = value.name.split('.');
        const format = nameParts[nameParts.length-1].toLowerCase();

        if (!['jpg', 'jpeg', 'png'].includes(format)) {
          return false;
        }

        return true;
      default:
        return !value;
    }
  };

  handleChange = event => {
    const {name, value} = event.target;

    this.setFieldData(name, value);
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({photoIsUploading: true});

    const {data} = this.state;

    const formData = new FormData();
    formData.append('file', data.file.value);

    const {authorization, locationId, addPhoto, getPhotos, history} = this.props;

    addPhoto(authorization, locationId, formData, () => {
      getPhotos(locationId, {}, () => {
        history.push(`/locations/${locationId}/photos`);
        this.setState({photoIsUploading: false});
      });
    });
  };

  render() {
    const {loading, error, locationId, history, t} = this.props;

    const allFieldsValid = Object.values(this.state.data)
      .filter(field => !field.valid).length === 0;

    if (loading || this.state.photoIsUploading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    return (
      <Container>
        {error && error.message && <ErrorAlert>{error.message}</ErrorAlert>}
        <Close onClick={() => history.push(`/locations/${locationId}/photos`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <Title>{t('addPhoto')}</Title>
          <UploadField
            type="image"
            allowedFormats={['jpg', 'jpeg', 'png']}
            handleChange={this.handleChange}
            label="Photo"
            buttonText="Choose a photo"
            warning="Unsupported format"
          />
          <ButtonContainer>
            <Button disabled={!allFieldsValid}
              type="submit" theme="blue" width="100%" margin="0" height="40px">
              {t('addPhoto')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {

  }
`;

export default compose(withTranslation(), withRouter)(AddPhotoForm);
