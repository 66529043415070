import React from "react";
import {connect} from "react-redux";
import * as colors from '../../util/colors';

import {getConnectorMeterValues} from "../../api";
import styled from "styled-components";
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import EvseMeterValuesExtend from "./EvseMeterValuesExtend";
import {EvseConnectorSettingsStyled, EvseSettingToggler} from "./EvseConnectorSettings";

const EvseMeterValues = ({stationId, number, authorization, serverTimeOffset}) => {
    const meterValues = useGetConnectorMeterValuesEffect(stationId, number, authorization);

    const [isOpened, setOpened] = React.useState(false);
    const toggleOpened = () => setOpened(!isOpened);

    const getMultiplierSymb = (multiplier) => ({
        1000: 'k',
        1000000: 'M',
        1000000000: 'G',
    }[multiplier] || '');

    let lastContext = '';

    return (
      <EvseConnectorSettingsStyled>
        <EvseSettingToggler onClick={toggleOpened} isOpened={isOpened}>
          <EvseTitle>Метрики</EvseTitle>
        </EvseSettingToggler>

        <br />

        {isOpened && (
            <EvseContent>
                <EvseMeterValuesContainer>
                    {meterValues.map((meter, i) => {
                        const [meterRelativeDate, meterFullDate] = getRelativeAndFullDatesWithServerOffset(meter.timestamp, serverTimeOffset);
                        const meterContext = meter.context ? meter.context : 'Основные';
                        const context = lastContext !== meterContext ? meterContext : '';
                        if (context) { lastContext = context; }
                        return (
                            <EvseMeterValuesStyled key={i}>
                                <EvseMeterValuesContextStyled>
                                    {context && context}
                                </EvseMeterValuesContextStyled>
                                {meter.measurand}
                                {meter.phase ? `(${meter.phase})` : ''}
                                {meter.location ? `, ${meter.location}` : ''}
                                {`: ${meter.value}`}
                                {' '}
                                {meter.multiplier && getMultiplierSymb(meter.multiplier)}
                                {meter.unit}
                                {!meter.multiplier || getMultiplierSymb(meter.multiplier) ? '' : `(*${meter.multiplier})`}
                                {' '}
                                <EvseMeterTimestamp>
                                    {meterRelativeDate}
                                    ({meterFullDate})
                                </EvseMeterTimestamp>
                                <br />
                            </EvseMeterValuesStyled>
                        )
                    })}

                <EvseMeterValuesExtend stationId={stationId} number={number} />

            </EvseMeterValuesContainer>
        </EvseContent>)}
      </EvseConnectorSettingsStyled>
    )
}

const useGetConnectorMeterValuesEffect = (stationId, number, authorization) => {
    const [meterValues, setMeterValues] = React.useState([]);

    React.useEffect(() => {
        getConnectorMeterValues(stationId, number, {authorization})
            .then(response => response.json())
            .then(data => {
                data = data.sort((a,b) =>
                    `${a.context}${a.measurand}${a.phase}`.localeCompare(`${b.context}${b.measurand}${b.phase}`)
                );
                setMeterValues(data);
            });
    }, [stationId, number, authorization]);

    return meterValues;
}

export const EvseMeterValuesContainer = styled.div`
  margin: -16px 0px 16px 8px;
`;

export const EvseMeterTimestamp = styled.span`
  display: none;
  color: ${colors.MARKER_GRAY};
`;

export const EvseMeterValuesStyled = styled.div`
  margin-left: 32px;
  font-family: monospace;
  font-size: 0.8rem;
  &:hover ${EvseMeterTimestamp} {
    display: inline;
  }
`;

export const EvseMeterValuesContextStyled = styled.div`
  font-size: 1rem;
  margin-left: 16px;
  margin-left: -32px;
  font-family: 'Open Sans', sans-serif;
`;

const EvseTitle = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: underline;
`;

const EvseContent = styled.div`
    border-left: 3px solid #eff1f3;
    margin-left: 35px;
`;

const mapStateToProps = ({manager, app}) => ({
    authorization: `Bearer ${manager.accessToken}`,
    serverTimeOffset: app.serverTimeOffset,
});

export default connect(mapStateToProps)(EvseMeterValues);
