import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import * as _ from 'lodash';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import SubmitForm, {SelectField, TextField} from '../../common/Form';
import Button from '../../common/Button';

export const CONNECTOR_TYPES = [
  {id: 0, name: 'Type 1', icon: '/connectors/type1.svg'},
  {id: 1, name: 'Type 2', icon: '/connectors/type2.svg'},
  {id: 2, name: 'CHAdeMO', icon: '/connectors/chademo.svg'},
  {id: 3, name: 'Schuko', icon: '/connectors/schuko.svg'},
  {id: 4, name: '3-pin', icon: '/connectors/3-pin.svg'},
  {id: 5, name: 'CCS 1', icon: '/connectors/ccs1.svg'},
  {id: 6, name: 'CCS 2', icon: '/connectors/ccs2.svg'},
  {id: 7, name: 'GB/T AC', icon: '/connectors/gbt-ac.svg'},
  {id: 8, name: 'GB/T DC', icon: '/connectors/gbt-dc.svg'},
  {id: 9, name: 'Tesla', icon: '/connectors/tesla.svg'},
];

export const CURRENT_TYPES = [{id: 0, name: 'AC'}, {id: 1, name: 'DC'}];

class CreateModelForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        vendor_name: {value: '', valid: undefined},
        model: {value: '', valid: undefined},
        image: {value: '', valid: undefined},
        evses: [
          {
            number: 1,
            connectors: [
              {
                number: 1,
                type: CONNECTOR_TYPES[0],
                current: CURRENT_TYPES[0],
                power: '',
              },
            ],
          },
        ],
      },
    };
  }

   handleChange = (event, connector) => {
     const {name, value} = event.target;

     if (connector) {
       this.setConnectorField(name, value, connector.evseNumber, connector.connectorNumber);
     } else {
       this.setField(name, value);
     }
   }

    handleDropdownChange = (evseIndex, connectorIndex, obj) => {
      const {data} = this.state;

      const key = obj.target.name;
      const {value} = obj.target;

      data.evses[evseIndex].connectors[connectorIndex][key] = (key === 'type') ? CONNECTOR_TYPES[value] : CURRENT_TYPES[value];

      this.setState({data});
    };

    setField = (name, value) => {
      const data = {...this.state.data};

      data[name].value = value;
      data[name].valid = true;

      this.setState({data});
    };

    setConnectorField = (name, value, evseNumber, connectorNumber) => {
      const {data} = this.state;

      const evseIndex = _.findIndex(data.evses, item => item.number === evseNumber);
      const connectorIndex = _.findIndex(data.evses[evseIndex].connectors, item => item.number === connectorNumber);

      data.evses[evseIndex].connectors[connectorIndex][name] = value;

      this.setState({data});
    }

    removeEvse(number) {
      const {data} = this.state;

      if (data.evses.length > 0) {
        _.remove(data.evses, (item) => item.number === number);
        this.setState({ data });
      }
    };

    addConnector(evseIndex) {
      const {data} = this.state;

      const evse = data.evses[evseIndex];

      const lastConnector = evse.connectors[evse.connectors.length - 1];

      evse.connectors.push({
        number: (lastConnector ? (lastConnector.number + 1) : 1),
        type: CONNECTOR_TYPES[0],
        current: CURRENT_TYPES[0],
        power: '',
      });

      this.setState(data);
    };

    removeConnector(evseIndex, connectorIndex) {
      const {data} = this.state;

      const evse = data.evses[evseIndex];

      if (evse.connectors.length > 0) {
        evse.connectors.splice(connectorIndex, 1);
        this.setState(data);
      }
    };

    formatStation() {
      const {data} = this.state;

      const station = {
        vendor_name: data.vendor_name.value,
        model: data.model.value,
        image: data.image.value,
        evses: data.evses,
      };

      for (const evse of data.evses) {
        for (const connector of evse.connectors) {
          connector.evse_number = evse.number;
          connector.type = connector.type.name;
          connector.current = connector.current.name;
          connector.power = parseFloat(connector.power);
        }
      }

      return station;
    };

    stationToState(station) {
      const data = {
        vendor_name: {value: station.vendor_name, valid: undefined},
        model: {value: station.model, valid: undefined},
        image: {value: station.image, valid: undefined},
        evses: [],
      };

      for (const evse of station.evses) {
        for (const connector of evse.connectors) {
          connector.evse_number = evse.number;
          connector.type = CONNECTOR_TYPES.find(type => (type.name === connector.type));
          connector.current = CURRENT_TYPES.find(type => (type.name === connector.current));
        }

        data.evses.push(evse);
      }

      this.setState({data});
    };

    allFieldsValid() {
      const {data} = this.state;

      const validVendorName = (data.vendor_name.value.length > 0);

      const validModel = (data.model.value.length > 0);

      let validPower = true;

      for (const evse of data.evses) {
        for (const connector of evse.connectors) {
          if (connector.power.length === 0) {
            validPower = false;
          }
        }
      }

      return (validVendorName && validModel && validPower);
    };

    // handleUploadPhoto = photo => {
    //
    // }

    addEvse() {
      const {data} = this.state;

      const lastEvse = data.evses[data.evses.length - 1];

      data.evses.push({
        number: (lastEvse ? (lastEvse.number + 1) : 1),
        connectors: [{number: 1, type: CONNECTOR_TYPES[0], current: CURRENT_TYPES[0], power: ''}]
      });

      this.setState({data});
    };

    render() {
      const {vendor_name, model, image} = this.state.data;
      const {station, t} = this.props;

      if (station && (this.state.data.vendor_name.value.length === 0)) {
        this.stationToState(station);
      }

      return (
        <Container>
          <Form>
            <Title>{t('createNewStationModel')}</Title>
            <InputContainer>
              <TextField
                name="vendor_name" value={vendor_name.value}
                label={t('manufacturer')}
                valid={vendor_name.valid} required
                handleChange={this.handleChange} handleBlur={() => false}
                removeIconVisible
              />
              <TextField
                name="model" value={model.value}
                label={t('model')}
                margin="20px 0 0"
                valid={model.valid} required
                handleChange={this.handleChange} handleBlur={() => false}
                removeIconVisible
              />
              <UploadContainer>
                <TextField
                  width="100%"
                  name="image" value={image.value}
                  label={`${t('image')} (URL)`}
                  margin="20px 0 0"
                  valid={image.valid} required={false}
                  handleChange={this.handleChange} handleBlur={() => false}
                  removeIconVisible
                />
                {/* <div className="or">
                    <span>Or</span>
                </div>
                <UploadField
                  name="image"
                  type="image"
                  margin="0 0"
                  width="30%"
                  height="0px"
                  buttonMargin="0 0"
                  allowedFormats={['jpg', 'jpeg', 'png']}
                  handleChange={this.handleUploadPhoto}
                  buttonText="Upload image"
                  warning="Unsupported format"/> */}
              </UploadContainer>
              <EvsesContainer>
                {this.state.data.evses.map((evse, evseIndex) => <EvseContainer number={evseIndex + 1} key={evseIndex}>
                  <div className="header">
                    <span>#{evseIndex + 1} EVSE</span>
                    {evseIndex !== 0 && <div className="remove">
                      <img src="/trashIcon.svg" alt="trash icon" height={25} width={25}
                        onClick={() => this.removeEvse(evse.number)}/>
                    </div> }
                  </div>

                  <hr/>

                  <div className="body">
                    {evse.connectors.map((connector, index) => <ConnectorContainer key={index}>
                      <div className="number">
                        #{index + 1}
                      </div>

                      <div className="field">
                        <SelectField
                          shadow="0px 2px 6px rgba(59, 87, 128, 0.16)"
                          name="type"
                          fontSize="1em"
                          selectedOption={connector.type}
                          options={CONNECTOR_TYPES}
                          optionsToggleTop="1em"
                          handleChange={this.handleDropdownChange.bind(null, evseIndex, index)}
                        />
                      </div>

                      <div className="power-field">
                        <TextField
                          name="power" value={connector.power}
                          placeholder={`${t('power')}, ${t('kW')}`}
                          valid
                          handleChange={e => this.handleChange(e, {
                            evseNumber: evse.number,
                            connectorNumber: connector.number
                          })}
                        />
                      </div>

                      <div className="ac-dc">
                        <SelectField
                          shadow="0px 2px 6px rgba(59, 87, 128, 0.16)"
                          name="current"
                          fontSize="1em"
                          selectedOption={connector.current}
                          options={CURRENT_TYPES}
                          optionsToggleTop="14px"
                          handleChange={this.handleDropdownChange.bind(null, evseIndex, index)}
                        />
                      </div>
                      {index !== 0 && <div className="remove">
                        <img src="/trashIcon.svg" alt="trash icon" height={25} width={25}
                          onClick={() => this.removeConnector(evseIndex, index)}/>
                      </div> }
                    </ConnectorContainer>)}

                    <Button
                      theme="gray"
                      width="100%"
                      margin="24px 0"
                      onClick={() => this.addConnector(evseIndex)}
                    >
                    + {t('connector')}
                    </Button>
                  </div>
                </EvseContainer>)}

                <Button
                  theme="white-blue"
                  width="100%"
                  margin="24px 0"
                  onClick={() => this.addEvse()}
                >
                + EVSE
                </Button>
              </EvsesContainer>
            </InputContainer>
            <ButtonContainer>
              <Button
                onClick={() => this.props.saveStation(this.formatStation())}
                disabled={!this.allFieldsValid()}
                theme="blue"
                width="100%"
              >
                {t('createModel')}
              </Button>
            </ButtonContainer>
          </Form>
        </Container>
      );
    };
}

const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  background-color: white;
  min-width: 700px; max-width: 50%;
  margin: auto;
  margin-bottom: 32px;
  margin-top: 32px;
  border-radius: 8px;
`;

const Form = styled(SubmitForm)`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  width: 300px;
  margin: 32px auto 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${colors.TEXT_TITLE};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 5.6vw 0;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EvsesContainer = styled.div`
  margin-top: 24px;
  overflow-x: hidden;
  height: 400px;
  width: 100%;
  &:-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const EvseContainer = styled.div`
  margin-top: ${props => (props.number > 1) ? '16px' : '0'};
  border: 1px solid ${colors.LINE}; border-radius: 5px;
  box-shadow: 0 0 5px ${colors.LINE};
  position: relative;
  width: 100%;
  align-content:center;

  &:-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  & > div.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-left: 24px; padding-right: 24px;
    padding-bottom: 2px;
    & > div.remove {
      cursor: pointer;
    }
  }
  & > div.body {
    padding-left: 24px; padding-right: 24px;
  }
`;

const ConnectorContainer = styled.div`
  & > div.number {
    float: left;
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
  }
  & > div.field {
    min-width: 35%; max-width: 35%;
    float: left;
    margin-left: 0.7vw;
  }
  & > div.power-field {
    min-width: 28%; max-width: 28%;
    float: left;
    margin-left: 0.7vw;
  }
  & > div.ac-dc {
    min-width: 17%; max-width: 17%;
    width: 20%;
    float: left;
    margin-left: 0.7vw;
  }
  & > div.remove {
    justify-self: flex-end;
    margin-left: 0.2em;
    cursor: pointer;
  }
  &:-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  padding: 0 5.6vw;
  width: 100%;
  padding-bottom: 32px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  & > div.or {
    margin: 48px 0 0;
    color: #818286;
    font-size: 14px;
  }
`;

export default compose(withTranslation(), withRouter)(CreateModelForm);
