import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';

class SearchForm extends Component {
  timeoutId = null;
  state = {
    text: '',
  };

  handleChange = event => {
    event.preventDefault();
    const {name, value} = event.target;

    if (name === 'text') {
      this.lazyChange(value);
    }

    this.setState({ [name]: value });
  };

  lazyChange = value => {
    const {authorization, onChange} = this.props;

    clearTimeout(this.lazyTimeoutId);

    this.lazyTimeoutId = setTimeout(() => {
      onChange(authorization, { search: value });
    }, 500);
  }

  render() {
    const {text} = this.state;
    const {t} = this.props;

    return (
      <Form onSubmit={event => event.preventDefault()}>
        <Field
          name="text"
          value={text}
          onChange={this.handleChange}
          placeholder={t('search')}
        />
        <Button>
          <i className="fa fa-search" />
        </Button>
      </Form>
    );
  }
}

const Form = styled.form`
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-height: 40px; max-height: 40px;
  border: 1px solid ${colors.LINE}; border-radius: 5px;
  min-width: 320px;
  max-width: 320px;
  margin-right: 18px;
`;

const Field = styled.input`
  position: absolute; top: 0; left: 0; bottom: 0;
  min-width: 280px; max-width: 280px;
  padding-left: 0.5rem;
  font-size: 16px;
  border: 1px solid transparent; border-radius: 10px 0 0 10px;
  background-color: white;
  outline: none;
`;

const Button = styled.button`
  position: absolute; top: 0; right: 0; bottom: 0;
  min-width: 40px; max-width: 40px;
  min-height: 40px; max-height: 40px;
  border: 1px solid transparent; border-radius: 0 10px 10px 0;
  border-left: 1px solid ${colors.LINE};
  color: ${colors.BUTTON_STROKE}; background-color: white;
`;
Button.defaultProps = {
  type: 'submit'
};

const mapStateToProps = ({manager}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default connect(mapStateToProps)(compose(withTranslation(), withRouter)(SearchForm));
