import React from "react";

import EvseConnectorSettings from "./EvseConnectorSettings";

const EvseConnectorsList = ({connectors, ...props}) => {
    return (
        <>
            {connectors.map((connector) => (
                <EvseConnectorSettings key={connector.id} {...connector} {...props}/>
            ))}
        </>
    );
}

export default EvseConnectorsList;
