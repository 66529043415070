import React, {Component} from 'react';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {loadPaymentsGroupedByPaymentMethodInDay} from "./paymentsData";

import {Column, InfiniteLoader, Table, defaultTableRowRenderer} from 'react-virtualized';
import Paper from '../common/Paper';
import {compose} from "redux";
import {connect} from "react-redux";
import {MONTHS_INTERVAL} from "../Dashboard/StatisticsIntervals";
import StatisticsDates from "../Dashboard/StatisticsDates";
import LocationsFilter from "../common/Locations/LocationsFilter";
import {Header, RightSide, Container, Title, Price} from "../Dashboard/ChargingHistoryContainer";
import {Dates, RowTitle} from "../Dashboard/StatisticsContainer.styled";
import ExportExcel from "./ExportExcel";
import {ReportsRow} from "./ReportsRow";
import {
  capturedAmount,
  renderAmount,
  renderGrayAmount,
  render4dColumn,
  renderFirstColumn,
  renderKwh,
  toCapturedAmount,
  toKWh,
  renderToCompensationAmount
} from "./PaymentsHistoryColumns";

const loadRowsLimit = 50000;

class PaymentsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      search: '',
      paymentsTotalCount: 0,

      expandedGroupIds: [],

      startDay: MONTHS_INTERVAL.startDay,
      startMonth: MONTHS_INTERVAL.startMonth,
      startYear: MONTHS_INTERVAL.startYear,

      endDay: MONTHS_INTERVAL.endDay,
      endMonth: MONTHS_INTERVAL.endMonth,
      endYear: MONTHS_INTERVAL.endYear,

      locationIds: [],
    };
  }

  componentDidMount() {
    this.loadMorePayments({startIndex: 0, stopIndex: loadRowsLimit});
  }

  getFromDate() {
    const {
      startDay,
      startMonth,
      startYear,
    } = this.state;

    const MM = moment().month(startMonth.id).format('MM');

    return moment(`${startDay.id}-${MM}-${startYear.id} 00:00:00`, 'DD-MM-YYYY');
  }

  getToDate() {
    const {
      endDay,
      endMonth,
      endYear
    } = this.state;

    const MM = moment().month(endMonth.id).format('MM');

    return moment(`${endDay.id}-${MM}-${endYear.id} 00:00:00`, 'DD-MM-YYYY').add(1, 'days');
  }

  batchLoadMorePaymentsOnScroll = async ({startIndex, stopIndex, search}) => {
    let lastKnown = startIndex;
    for (let i = stopIndex; i > startIndex; i--) {
      if (!this.state.orders[i]) { continue;}
      lastKnown = i;
      break;
    }
    const minBatchCount = 9;
    const batchCountToLoad = loadRowsLimit;
    if (stopIndex - lastKnown >= minBatchCount) {
      // this.loadMorePayments({startIndex: lastKnown + 1, stopIndex: lastKnown + 1 + batchCountToLoad}); // TODO: uncomment and prevent multiple calls
    }
  };

  loadMorePayments = async ({startIndex, stopIndex, search}) => {
    const { payments, paymentsTotalCount } = await this.loadPayments({
      limit: stopIndex - startIndex + 1,
      offset: startIndex,
    });

    this.setState({
      paymentsTotalCount,
      orders: payments,
    });
  };

  setStateAndReload(state) {
    this.setState({...state, orders: []}, () => {
      this.loadMorePayments({startIndex: 0, stopIndex: loadRowsLimit});
    });
  }

  rowGetter = (value) => this.state.orders[value.index];

  rowLoaded = index => !!this.state.orders[index];

  loadPayments = (options) => {
    return loadPaymentsGroupedByPaymentMethodInDay({
      from: this.getFromDate().toISOString(),
      to: this.getToDate().toISOString(),
      locationIds: this.state.locationIds?.join(',') || '',
      ...options,
    }, this.props.authorization);
  }

  onRowClick = ({ rowData }) => {
    if (!rowData.groupId) {
      return;
    }

    const { expandedGroupIds } = this.state;

    if (expandedGroupIds.includes(rowData.groupId)) {
      this.collapsePaymentGroup(rowData.groupId);

      this.setState({
        expandedGroupIds: expandedGroupIds.filter((groupId) => groupId !== rowData.groupId),
      })
    } else {
      this.expandPaymentGroup(rowData.groupId);

      this.setState({
        expandedGroupIds: [...expandedGroupIds, rowData.groupId],
      })
    }
  }

  expandPaymentGroup = (groupId) => {
    const orders = [...this.state.orders];
    const rowData = this.state.orders.find((rowData) => rowData.groupId === groupId);
    const index = this.state.orders.indexOf(rowData);

    orders.splice(index + 1, 0,  ...rowData.payments);

    this.setState({
      orders,
    })
  }

  collapsePaymentGroup = (groupId) => {
    this.setState({
      orders: this.state.orders.filter(({parentId}) => parentId !== groupId),
    })
  }

  rowRenderer = (props) => defaultTableRowRenderer({
    ...props,
    className: props.rowData.summary
        ? `${props.className} titleRowSummary`
        : (props.rowData.groupId ? `${props.className} titleRowPayments` : props.className),
  });

  render() {
    const {props} = this;
    const {t} = props;

    return (
      <Paper padding="0">
        <Container>
          <Header>
            <Title>Статистика оплат</Title>
          </Header>

          <Header>
            <Title />

            <RightSide>
              <LocationsFilter onChange={(locationIds) => this.setStateAndReload({ locationIds })} />
            </RightSide>
          </Header>

          <StatisticsDates
            data={this.state}
            handleChange={(state) => this.setStateAndReload(state)}
          />

          <ReportsRow>
            <RowTitle>Загрузить отчеты: </RowTitle>
            <div>
              {(this.props.isAdministrator || this.props.isAccounter)
                && <ExportExcel
                    mode={`owner`}
                    title={`По партнерам`}
                    from={this.getFromDate().toISOString()}
                    to={this.getToDate().toISOString()}
                    locations={this.state.locationIds?.join(',') || ''}
                />
              }

              <ExportExcel
                  mode={`day`}
                  title={`По дням`}
                  from={this.getFromDate().toISOString()}
                  to={this.getToDate().toISOString()}
                  locations={this.state.locationIds?.join(',') || ''}
              />
              <ExportExcel
                  mode={`location`}
                  title={`По локациям`}
                  from={this.getFromDate().toISOString()}
                  to={this.getToDate().toISOString()}
                  locations={this.state.locationIds?.join(',') || ''}
              />
              <ExportExcel
                  mode={`detailed`}
                  title={`Детальный`}
                  from={this.getFromDate().toISOString()}
                  to={this.getToDate().toISOString()}
                  locations={this.state.locationIds?.join(',') || ''}
              />
            </div>
          </ReportsRow>

          {(this.props.isAdministrator || this.props.isAccounter)
            && <ReportsRow>
              <RowTitle />
              <div>
                <ExportExcel
                      mode={`income`}
                      title={`Поступления`}
                      from={this.getFromDate().toISOString()}
                      to={this.getToDate().toISOString()}
                />

                {(this.props.isAdministrator)
                  && <ExportExcel
                        mode={`account`}
                        title={`Личный счет`}
                        from={this.getFromDate().toISOString()}
                        to={this.getToDate().toISOString()}
                  />}
              </div>
            </ReportsRow>
          }

          <InfiniteLoader
            isRowLoaded={this.rowLoaded}
            rowCount={Infinity}
            threshold={10}
            loadMoreRows={this.batchLoadMorePaymentsOnScroll}
          >
            {({onRowsRendered, registerChild}) => (
              <Table
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={52}
                headerHeight={52}
                width={1015}
                height={500}
                rowClassName="ordersRow"
                rowGetter={this.rowGetter}
                rowCount={this.state.orders.length}
                onRowClick={this.onRowClick}
                rowRenderer={this.rowRenderer}
              >
                <Column width={185}
                  dataKey='payment_method_type'
                  label={'Метод оплаты'}
                  style={{paddingLeft: '12px',paddingRight: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => renderFirstColumn(rowData)}
                />
                <Column width={90}
                  dataKey='count'
                  label={'Кол-во оплат'}
                  style={{paddingLeft: '26px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => render4dColumn(rowData)}
                />
                <Column width={105}
                  dataKey='kwh'
                  label={t('kWh')}
                  style={{paddingLeft: '12px', paddingRight: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => renderKwh(rowData)}
                />
                <Column width={135}
                  dataKey='amount'
                  label={t('chargingRevenue')}
                  style={{paddingLeft: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => capturedAmount(cellData, rowData)}
                />
                <Column width={115}
                  dataKey='bank_commission'
                  label={'Комиссия, банк'}
                  style={{paddingLeft: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => renderGrayAmount(rowData.bank_commission)}
                />
                <Column width={115}
                  dataKey='agent_fee'
                  label={'Комиссия, агент'}
                  style={{paddingLeft: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => renderGrayAmount(rowData.agent_fee)}
                />
                <Column width={115}
                  dataKey='compensation'
                  label={'Возмещено'}
                  style={{paddingLeft: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => renderGrayAmount(rowData.compensation)}
                />
                <Column width={135}
                  dataKey='to_compensation'
                  label={'К возмещению'}
                  style={{paddingLeft: '12px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => renderToCompensationAmount(rowData)}
                />
              </Table>
            )}
          </InfiniteLoader>
        </Container>
      </Paper>
    );
  }
}

const mapStateToProps = ({ manager }) => ({
  isAdministrator: manager.data?.role === 'administrator',
  isAccounter: manager.data?.role === 'accounter',
});

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(PaymentsHistory);
