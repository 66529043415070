import {GET_STATIONS_START, GET_STATIONS_SUCCESS, GET_STATIONS_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  lastLoad: false,
  error: null,
};

const stations = (state = initialState, action) => {
  switch (action.type) {
    // get stations
    case GET_STATIONS_START:
      return {...state, loadingMore: true};
    case GET_STATIONS_SUCCESS:
      return {
        ...state,
        params: action.params,
        data: action.loadMore ? [ ...state.data, ...action.data ] : action.data,
        date: action.date,
        lastLoad: action.data.length < action.params.limit,
        error: null,
        loadingMore: false,
      };
    case GET_STATIONS_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default stations;
