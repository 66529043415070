import React, {Component} from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import {compose} from "redux";
import {connect} from "react-redux";
import styled from 'styled-components';
import * as _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {loadOrders} from "./ChargingNowData";
import * as colors from '../../util/colors';

import {Column, Table} from 'react-virtualized';
import Avatar from '../common/Avatar';
import Paper from '../common/Paper';
import {connectorType, Data} from "./ChargingNowColumns";
import {TableWrapper} from "../ChargePoints/StationsTable.styled";
import {Parking, ParkingPrice} from "./ChargingHistoryContainer";

moment().locale('ru');

class ChargingNowContainer extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);

    this.state = {
      from: moment().subtract(3, 'days').toISOString(),
      to: moment().add(1, 'days').toISOString(),
      orders: [],
      search: '',
    };
  }

  componentDidMount() {
    this.loadOrdersOnMount({startIndex: 0, stopIndex: 50});
    this.intervalId = setInterval(() => this.loadOrdersOnMount({startIndex: 0, stopIndex: 50}), 0.25 * 60 * 1000); // TODO: 1 min
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }


  loadOrdersOnMount = async ({startIndex, stopIndex, search}) => {
    const orders = await loadOrders({
      from: this.state.from,
      to: this.state.to,
      search: this.state.search,
      limit: stopIndex - startIndex + 1,
      offset: startIndex,
      authorization: this.props.authorization,
    });

    this.setState({orders: orders});
  };

  rowGetter = (value) => this.state.orders[value.index];

  renderLocation = (location) => {
    if (!location) {
      return (<Loading width={this.randomWidth}/>);
    }

    const locationTitle = location.street === undefined
        ? 'Loading...'
        : `${location.street}, ${location.city}, ${location.country}`;
    return (
        <Data>
          <Location>
            <div className="name">{location.name}</div>
            {this.props.isAdministrator
                ? <a className="address" href={`/locations/${encodeURIComponent(location.id)}/stations`}>{locationTitle}</a>
                : <a className="address">{locationTitle}</a>
            }
          </Location>
        </Data>
    )};

  renderStation = (connector, station) => {
    const {t} = this.props;

    return station ? (
      <Data>
        <Station>
          {station && station.number && (this.props.isAdministrator || this.props.isManager || this.props.isOperator
                  ? <a className="station" href={`/charge-points/${encodeURIComponent(station.id)}/information`}>{station.id}</a>
                  : <a className="station">{station.id}</a>
          )}
        </Station>
      </Data>
    ) : (
      <Loading width={this.randomWidth}/>
    );
  }

  renderChargingTime = (rowData) => {
    const diff = +moment() - +moment(rowData.charging_start || rowData.reservation_start);
    const ago = moment().subtract(diff, 'milliseconds').fromNow();
    return (
      <Data>
        <div>
          <Datetime>
            <div className="time">
              {timestampToString(rowData.charging_start || rowData.reservation_start)[0]}
            </div>
            <div className="date row">
              {ago}
            </div>
          </Datetime>
        </div>
      </Data>
    );
  }

  renderSoc = (rowData) => {
    let soc = '';
    soc = rowData.meter_soc_start || rowData.meter_soc_start === 0
      ? `${rowData.meter_soc_start} %`
      : '?';

    soc = rowData.meter_soc_stop
      ? `${soc} - ${rowData.meter_soc_stop} %`
      : '';

    return (
      <Data>
        <div>
          <Soc>{soc}</Soc>
        </div>
      </Data>
    );
  }

  price = price => <Data><Price value={price}>{price / 100}₽</Price></Data>;

  capturedAmount = (rowData) => {
    const amount = rowData.current_charging_amount;
    const status = rowData.status;
    const reservation = rowData.reservation_price;
    if (reservation > 0) {
      return (
          <Parking value={amount}>
            <div className="name"><ParkingPrice isWrong={false} value='0'>{amount/100} {amount > 0 ? '₽' : ''}</ParkingPrice></div>
            <div className="name"><ParkingPrice isWrong={false} value='0'>+{reservation / 100} бронь</ParkingPrice></div>
          </Parking>
      );
    }

    if (status === 'started') {
      return (
          <Data><Price isWrong={false} value={amount}>{amount / 100}₽</Price></Data>
      );
    }

    return (
        <Data><div/></Data>
    );
  };


  pdf = () => <Data width='100px'><PdfButton>PDF <i className="fa fa-arrow-down" /></PdfButton></Data>;

  kwh = rowData => (<Data>
    <div>
      <CurrentKwh>
        <div className="kwh row">{rowData.status === 'started' ? parseFloat((rowData.current_wh / 1000).toFixed(2)) : ''}</div>
        <div className="kw row">
          {rowData.status === 'started' && rowData.current_w > 0 ? parseFloat((rowData.current_w / 1000).toFixed(1)) + ' kW' : ''}
        </div>
      </CurrentKwh>
    </div>
  </Data>);

  status = status => <Data><Status value={status}>{status}</Status></Data>

  name = (user, rfid) => user ? (
    <Data>
      <Avatar
        size="32px"
        rounded
        style={{ marginRight: '8px' }}
        imageSrc={(user && user.avatar_url) ? user.avatar_url : null}
      />
      <Location>
        <div className="name">{user.phone || 'Unknown'}{rfid ? (<Rfid>RFID</Rfid>) : ''}</div>
        <div className="address">
          {user && user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : 'Unknown'}
        </div>
      </Location>
    </Data>
  ) : (
    <Loading width={this.randomWidth}/>
  );

  rowLoaded = index => !!this.state.orders[index];

  // Max: 70. Min: 35.
  randomWidth = () => [(Math.floor(Math.random() * (70 - 35 + 1)) + 35), 'px'].join('');

  render() {
    const {props} = this;
    const {t, history} = props;

    return (
      <Paper padding="0">
        <Container>
          <Header>
            <Title>{t('chargingNow')}</Title>
            {/*<RightSide>*/}
            {/*  <SearchForm onChange={this.onSearchChange} />*/}
            {/*</RightSide>*/}
          </Header>
          {/* <Searchbar /> */}
          { this.state.orders.length > 0
            ? <TableWrapper>
                <Table
                  rowHeight={52}
                  headerHeight={52}
                  width={1400}
                  autoHeight={true}
                  height={900}
                  rowClassName="ordersRow"
                  rowGetter={this.rowGetter}
                  rowCount={this.state.orders.length}
                >
                  <Column width={250}
                    dataKey='user_id'
                    label={t('client')}
                    style={{paddingLeft: '24px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.name(rowData.user, rowData.rfid)}
                  />
                  <Column width={120}
                    dataKey='status'
                    label={t('status')}
                    style={{paddingLeft: '14px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({cellData}) => this.status(cellData)}
                  />
                  <Column width={330}
                    dataKey='location'
                    label={t('locations')}
                    style={{paddingLeft: '12px',paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.renderLocation(rowData.location)}
                  />
                  <Column width={150}
                    dataKey='evse_number'
                    label={t('station')}
                    style={{paddingLeft: '12px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.renderStation(rowData.connector, rowData.station)}
                  />
                  <Column width={150}
                    dataKey='connector_id'
                    label={t('connectorType')}
                    style={{paddingLeft: '26px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => connectorType(rowData.connector)}
                  />
                  <Column width={195}
                    dataKey='charging_start'
                    label={t('time')}
                    style={{paddingLeft: '12px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.renderChargingTime(rowData)}
                  />
                  <Column width={175}
                    dataKey='soc'
                    label={t('evSoc')}
                    style={{paddingLeft: '12px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.renderSoc(rowData)}
                  />
                  <Column width={120}
                    dataKey='meter_stop'
                    label={t('kWh')}
                    style={{paddingLeft: '12px', paddingRight: '24px'}}
                    headerClassName="headerOrders"
                    cellRenderer={({rowData}) => this.kwh(rowData)}
                  />
                  <Column width={150}
                      dataKey='captured_amount'
                      label={t('chargingRevenue')}
                      style={{paddingLeft: '12px'}}
                      headerClassName="headerOrders"
                      cellRenderer={({cellData, rowData}) => this.capturedAmount(rowData)}
                  />
                  <Column width={35}
                           dataKey='order_details'
                           label=''
                           style={{paddingLeft: '1px'}}
                           headerClassName="headerOrders"
                           cellRenderer={({cellData, rowData}) => (!this.props.isAdministrator && !this.props.isOperator
                                  ? (<i/>)
                                  : (<a
                                      href={`/orders/${encodeURIComponent(rowData.id)}/information`}
                                      style={{color: '#212529'}}
                                      title={'Детали заказа'}
                                      className="fa fa-list"
                                  />)
                           )}
                  />
                </Table>
            </TableWrapper>
          : <Text textAlign="left">{t('chargingNotExists')}</Text>}
        </Container>
      </Paper>
    );
  }
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
`;

export const Title = styled.h2`
  margin: 24px;
  font-size: 20px;
  font-weight: bold;
`;

const Status = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 24px; max-height: 24px;
  padding: 0 12px;
  border: 1px solid transparent; border-radius: 18px;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  background-color: ${props => statusColorByValue(props.value)};
`;

export const Location = styled.div`
  font-size: 0.875em;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  & > div.name {
    font-weight: bold;
    min-width: 250px; max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > .address {
    min-width: 250px; max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > a.address {
    color: ${colors.TEXT_MINOR};
    min-width: 100%;
    &:hover {
      background-color: #F3F5F6;
    }
  }
`;

export const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: black;
`;

const Station = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 5px;
  & a.station {
    color: ${colors.TEXT_MINOR};
    font-size: smaller;
    min-width: 100%;
    &:hover {
      background-color: #F3F5F6;
    }
  }
`;

export const Loading = styled.div`
  min-width: ${props => props.width || '15px'};
  width: ${props => props.width || '15px'};
  height: 20px;
  display: block;
  background-color: #DDD;
`;

const Datetime = styled.div`
  & > div.time {
    font-weight: bold;
    min-width: 100%;
    margin-left: 0;
  }
  & > div.date {
    color: ${colors.TEXT_MINOR};
    font-size: 0.875em;
    min-width: 100%;
    margin-left: 0;
    white-space: nowrap;
  }
`;

const Soc = styled.div`
  color: ${colors.TEXT_MINOR};
  font-size: 0.875em;
  min-width: 100%;
  margin-left: 0;
`;

const CurrentKwh = styled.div`
  & > div.kwh {
    font-weight: bold;
    min-width: 100%;
    margin-left: 0;
  }
  & > div.kw {
    color: ${colors.TEXT_MINOR};
    min-width: 100%;
    margin-left: 0;
  }
`;

const Text = styled.div`
  margin: 0 0 20px 25px;
  font-size: '14px';
  font-weight: 'normal';
  margin-top: 10px;
  margin-bottom: 10px;
  color: #818286;
  padding-bottom: 16px;
`;

const statusColorByValue = status => {
  switch (status) {
    case 'canceled':
    case 'expired':
      return colors.ERROR;
    case 'completed':
      return colors.ICON_GREEN;
    case 'started':
      return colors.ICON_BLUE;
    case 'active':
      return colors.ICON_LIGHT_GREEN;
    case 'starting':
      return colors.ICON_LIGHT_BLUE;
    case 'countdown':
      return colors.ICON_ORANGE;
    case 'wait_payment':
      return colors.ICON_YELLOW;
    default:
      return colors.ERROR;
  }
};

// TODO Code reusability.
export const connectorImageByType = connectorType => {
  if (!connectorType || typeof connectorType !== 'string') {
    return '/connectors/type1.svg';
  }
  switch (connectorType.toLowerCase()) {
    case 'type 1':
      return '/connectors/type1.svg';
    case 'type 2':
      return '/connectors/type2.svg';
    case 'ccs':
      return '/connectors/ccs.svg';
    case 'schuko':
      return '/connectors/schuko.svg';
    case 'chademo':
      return '/connectors/chademo.svg';
    case '3-pin':
      return '/connectors/3-pin.svg';
    case 'ccs 1':
      return '/connectors/ccs1.svg';
    case 'ccs 2':
      return '/connectors/ccs2.svg';
    case 'gb/t ac':
      return '/connectors/gbt-ac.svg';
    case 'gb/t dc':
      return '/connectors/gbt-dc.svg';
    case 'tesla':
      return '/connectors/tesla.svg';
    default:
      return '/connectors/type1.svg';
  }
};

const PdfButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 72px; max-width: 72px;
  min-height: 24px; max-height: 24px;
  padding: 0 12px;
  border: 1px solid transparent; border-radius: 36px;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.BLUE_MAIN};
  background-color: ${colors.BLUE_LIGHT};
  cursor: not-allowed;
  & > i.fa {
    margin-left: 6px;
  }
  /**/display: none;
`;
PdfButton.defaultProps = { disabled: true };

const Rfid = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    min-width: 35px;
    height: 13px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    padding: 1px;
    background-color: red;
`;

const timestampToString = timestamp => {
  const date = new Date(timestamp);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = [
    'January', 'February',
    'March', 'April', 'May',
    'June', 'July', 'August',
    'September', 'October', 'November',
    'December'
  ][monthIndex];
  const year = 1900 + date.getYear();

  if (hours.length !== 2) {
    hours = `0${hours}`;
  }
  if (minutes.length !== 2) {
    minutes = `0${minutes}`;
  }
  if (seconds.length !== 2) {
    seconds = `0${seconds}`;
  }
  return [`${hours}:${minutes}:${seconds}`, `${day} ${month} ${year}`];
};

const mapStateToProps = ({ manager }) => ({
  isAdministrator: manager.data?.role === 'administrator',
  isManager: manager.data?.role === 'manager',
  isOperator: manager.data?.role === 'operator',
});

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  withRouter
)(ChargingNowContainer);
