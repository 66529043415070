import React from 'react';

import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';
import Paper from '../common/Paper';
import {Navigation} from "../Location/LocationCard.styled";
import {compose} from "redux";
import {connect} from "react-redux";


const OrderTabs = props => {
    const {
        orderId,
        isAdministrator,
    } = props;

    return (
        <Paper padding="0">
            <Navigation>
                {isAdministrator
                    ? <TabLinkGroup>
                        <TabLink to={`/orders/${orderId}/information`}>Информация</TabLink>
                        <TabLink to={`/orders/${orderId}/console`}>Ocpp логи</TabLink>
                    </TabLinkGroup>
                    : <TabLinkGroup>
                        <TabLink to={`/orders/${orderId}/information`}>Информация</TabLink>
                    </TabLinkGroup>
                }
            </Navigation>
        </Paper>
    );
};

const mapStateToProps = ({manager}, {match}) => ({
    isAdministrator: manager.data?.role === 'administrator',
});

export default compose(
    connect(mapStateToProps),
)(OrderTabs);
