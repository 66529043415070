import React, {useState} from 'react';
import {connect} from "react-redux";
import setTariffs from "../../../redux/actions/setTariffs";
import {CONNECTOR_TYPES} from "../../Location/AddStation/CreateModelForm";

import {Container} from "reactstrap";
import TopbarMobile from "../../common/TopbarMobile";
import Sidebar from "../../common/Sidebar";
import {PageBlock, PageInnerBlock, TopBarBlock, WrapperBlock} from "../../common/MainBlocks";
import Form, {SelectField, TextField, Title} from "../../common/Form";
import TopbarBackButton from "../../common/TopbarBackButton";
import LocationsFilter from "../../common/Locations/LocationsFilter";
import Alert from "../../common/Alerts/Alert";
import Button from "../../common/Button";
import {ErrorAlert} from "../../common/Alerts";
import LoadingSpinner from "../../common/LoadingSpinner";

const CONNECTOR_OPTIONS = [
  {id: 'all', name: 'Все коннекторы'},
  {id: 'ac', name: 'Все AC коннекторы'},
  {id: 'dc', name: 'Все DC коннекторы'},
  ...CONNECTOR_TYPES.map(type => ({id: type.name, name: type.name})),
]

const ChangeTariffs = ({
  loading,
  onSubmit,
}) => {
  const [connector, setConnector] = useState(CONNECTOR_OPTIONS[0]);
  const [price, setPrice] = useState(1700);
  const [locations, setLocations] = useState([]);
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();

    onSubmit({
      locations,
      price: +price,
      connectors: [connector.id],
    }, (data, error) => {
      setAlert(data?.count || null);
      setError(error);
    });
  };

  return (
    <WrapperBlock>
      <TopbarMobile />
      <Sidebar/>
      <PageBlock>
        <PageInnerBlock>
          <TopBarBlock fixed white>
            <TopbarBackButton
              to={`/locations`}
              label='Назад'
            />
          </TopBarBlock>

          <Container>
            {error && <ErrorAlert>{error}</ErrorAlert>}

            {loading && <LoadingSpinner />}

            <Title>Изменить тарифы</Title>

            {!loading && (<Form onSubmit={handleSubmit}>
              <LocationsFilter
                onChange={setLocations}
              />

              <div className="field">
                <SelectField
                  shadow="0px 2px 6px rgba(59, 87, 128, 0.16)"
                  name="type"
                  fontSize="1em"
                  selectedOption={connector}
                  options={CONNECTOR_OPTIONS}
                  optionsToggleTop="1em"
                  handleChange={(e) => setConnector(CONNECTOR_OPTIONS.find(c => c.id === e.target.value))}
                />
              </div>

              <TextField
                value={price}
                label="Новый тариф за кВтч, в копейках"
                handleChange={(e) => setPrice(e.target.value)}
              />

              <Button
                type="submit"
                width="100%"
              >
                Изменить тариф на {locations.length === 0 ? 'всех' : locations.length} локациях
              </Button>

            </Form>)}
          </Container>

          {alert !== null && (
            <Alert
              onClose={() => setAlert(null)}
              title={`Тариф изменен для ${alert} станций`}
            />
          )}

        </PageInnerBlock>
      </PageBlock>
    </WrapperBlock>
  )
};

const mapDispatchToProps = {
  onSubmit: setTariffs,
};

export default connect(null, mapDispatchToProps)(ChangeTariffs);
