import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getOwnerNameByData} from "../../../util/owner";

import getLocationsAction from "../../../redux/actions/getLocations";
import SelectItemsModal from "../SelectItemsModal";

function LocationsModal({t, loading, data, authorization, isOpen, onChnage, onCancelClick, getLocations}) {
  const [selectedLocations, setSelectedLocations] = React.useState([]);

  useLoadLocationsEffect(getLocations, authorization, isOpen);
  useDefaultAllLocationsSelectedEffect(selectedLocations, setSelectedLocations, data);

  const groupedData = groupItemsByOwner(data);

  return (
    <SelectItemsModal
      isOpen={isOpen}
      title={t('locations')}
      items={groupedData}
      onChange={onChnage}
      onCancelClick={onCancelClick}
      loading={loading}
      defaultSelectedIds={selectedLocations}
    />
  )
}

const groupItemsByOwner = (items) => {
  const itemsByOwner = {};

  for (const item of items) {
    if (!itemsByOwner[item.owner_id]) {
      itemsByOwner[item.owner_id] = {
        id: item.owner_id,
        name: getOwnerNameByData(item),
        items: [],
      };
    }

    itemsByOwner[item.owner_id].items.push(item);
  }

  const groupedItems = Object.values(itemsByOwner);

  for (const item of groupedItems) {
    item.items = item.items.sort(sortItemsByName);
  }

  return groupedItems.sort(sortItemsByName);
};

const sortItemsByName = (a, b) => a.name?.localeCompare(b.name, undefined, {numeric: true});

const useDefaultAllLocationsSelectedEffect = (selectedLocations, setSelectedLocations, data) => React.useEffect(() => {
  if (!selectedLocations.length) {
    const allLocations = data.map(({id}) => id);

    setSelectedLocations(allLocations);
  }
}, [data]);

const useLoadLocationsEffect = (getLocations, authorization, isOpen) => React.useEffect(() => {
  if (isOpen) {
    getLocations(
      authorization,
      { mode: 'full' },
      () => { },
      false,
    );
  }
}, [isOpen]);

const mapStateToProps = ({manager, locations: { data, loading }}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  data,
  loading,
});

const mapDispatchToProps = {
  getLocations: getLocationsAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LocationsModal);
