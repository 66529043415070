import {postJSON as post} from '../../api';

const setTariffs = (body, callback) => {
  return async (dispatch, getState) => {
    const { manager } = getState();
    const authorization = manager.accessToken && `Bearer ${manager.accessToken}`;

    try {
        const response = await post(`/locations/pricing`, {authorization}, body);

        if ((response.status >= 200) && (response.status < 300)) {
            callback && callback(await response.json());
            return;
        }
        if ((response.status >= 400) && (response.status < 500)) {
            const json = await response.json();
            if (callback && json && json.error) {
                callback(null, json.error);
            }
            return;
        }
        callback && callback(null, response.statusText)

    } catch (error) {
        callback && callback(null, error.message);
    }
  };
};


export default setTariffs;
