import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import {getOcppMessages, postStationMessage} from "../../api";

import Paper from "../common/Paper";
import {SelectField, TextField} from "../common/Form";
import TitleField from "../common/TitleField";
import {FieldContainer} from "../Location/LocationSettings/LocationSettingsForm";
import Button from "../common/Button";
import {Title} from "../Location/LocationInfo.styled";
import {ErrorAlert} from "../common/Alerts";
import Alert from "../common/Alerts/Alert";

function StationConsoleTab({ stationId, authorization }) {
  const [consoleMessages, reloadMessages] = useConsoleMessagesEffect(stationId, authorization);
  const {
    sendMessage,
    data,
    handleChange,
    error,
    response,
    clearResponse,
  } = useSendMessageEffect(stationId, authorization);

  const closeResponse = () => {
    clearResponse();
    reloadMessages();
  }

  const options = [
    { id: 0, name: 'Шаблоны ...'},
    { id: 1, name: 'Перезагрузка', action: 'Reset', payload: '{"type": "Soft"}'},
    { id: 2, name: 'Ограничить ток', action: 'ChangeConfiguration', payload: '{"key":"_IMAX1","value":50}'},
    { id: 3, name: 'Показать ограничение тока', action: 'GetConfiguration', payload: '{"key": ["_IMAX3"]}'},
    { id: 4, name: 'Запросить статусы', action: 'TriggerMessage', payload: '{"requestedMessage": "StatusNotification"}'},
    { id: 5, name: 'Установить логирование', action: 'ChangeConfiguration', payload: '{"key":"_LOG","value":15}'},
    { id: 6, name: 'Переключить на прод', action: 'ChangeConfiguration', payload: '{"key":"_IP1","value":"csms.drivechargers.ru"}'},
    { id: 7, name: 'Переключить на дев', action: 'ChangeConfiguration', payload: '{"key":"_IP1","value":"csms.drvdev.ru"}'},
    { id: 8, name: 'Автономность (1)', action: 'ChangeConfiguration', payload: '{"key":"_TAG1","value":"false"}'},
    { id: 9, name: 'Автономность (2)', action: 'ChangeConfiguration', payload: '{"key":"_CFG1","value":"AllUsers"}'},
    { id: 10, name: 'Отмена автономности (1)', action: 'ChangeConfiguration', payload: '{"key":"_TAG1","value":"true"}'},
    { id: 11, name: 'Отмена автономности (2)', action: 'ChangeConfiguration', payload: '{"key":"_CFG1","value":"CentralSystem"}'},
    { id: 12, name: 'Hard(!) перезагрузка', action: 'Reset', payload: '{"type": "Hard"}'},
  ];
  const findOptionByValue = value => {
    return options.find(option => option.id === value);
  };
  const [selectedOption, selectOption] = React.useState(options[0]);

  const onSelectChange = (e) => {
    const option = findOptionByValue(e.target.value);
    if (option.action) {
      data.action = option.action;
      data.payload = option.payload;
    } else {
      data.action = '';
      data.payload = '';
    }
    return selectOption(option);
  };

  return (
    <>
      <ConsoleMessages
        consoleMessages={consoleMessages}
        reloadMessages={reloadMessages}
      />

      <Paper>
        <Title>Send Command</Title>
        {error && (
          <ErrorAlert>{error}</ErrorAlert>
        )}
        <FieldContainer style={{display: 'none'}}>
          <TitleField>
            Data request
          </TitleField>
          <SelectField
            noDot
            // selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            // optionHoverColor="#E1EEFF"
            // name="data_request"
            width='150px'
            selectedOption={{name: 'On'}}
            options={[{name: 'On'}, {name: 'Off'}]}
            // handleChange={props.handleChange}
          />
          <Button
            // onClick={handleSave}
            style={{marginLeft: '20px'}}
            minWidth="131px"
          >
            Send
          </Button>
        </FieldContainer>
        <FieldContainer>
          <TitleField>
            Command
          </TitleField>
          <TextField
            name="action"
            value={data.action}
            handleChange={handleChange}
            width="calc(100% - 300px)" margin="0 12px 0 0"
          />
        </FieldContainer>
        <FieldContainer>
          <TitleField>
            Command Body (JSON)
          </TitleField>
          <TextField
            name="payload"
            value={data.payload}
            handleChange={handleChange}
            width="calc(100% - 300px)" margin="0 12px 0 0"
            height="100px"
            multiline
          />
        </FieldContainer>
        <FieldContainer>
          <ButtonsRow>
            <div style={{marginLeft: '16px'}}>
              <SelectField
                  noDot
                  selectedFieldStyle={{border: '1px solid #CAD0D9'}}
                  optionHoverColor="#E1EEFF"
                  name='messageTpl'
                  width='275px'
                  selectedOption={selectedOption}
                  options={options}
                  handleChange={onSelectChange}
              />
            </div>
          </ButtonsRow>
          <Button style={{marginLeft: 'auto'}} onClick={sendMessage}>
            Send
          </Button>
        </FieldContainer>
      </Paper>

      {response && (
        <Alert
          onClose={closeResponse}
          title={response}
        />
      )}
    </>
  );
}

const ConsoleMessages = ({consoleMessages, reloadMessages}) => {
  const [isFullscreen, setFullscreen] = React.useState(false);
  const toggleFullscreen = function () {
    setFullscreen(!isFullscreen);
  };

  const ref = useScrollToBottomEffect(consoleMessages);

  const consoleMessagesReversed = [...consoleMessages].reverse();

  const formatMessage = ({
                           message_from,
                           message_type_id,
                           action,
                           payload,
                           created_at,
                         }) => (
    <div style={{
      3: {color: '#9acaff'},
      4: {color: '#dc75a5'},
    }[message_type_id]}>
        <CreatedAt title={message_from === 'station' ? 'входящее(от станции)' : 'исходящее(от сервера)'}>{message_from === 'station' ? '≫' : '≪'} </CreatedAt>
        <CreatedAt>{created_at}; </CreatedAt>
        <Action>{action} </Action>
        <Payload>{JSON.stringify(payload, null, ' ').replace(/(;)/gi,'$1 ')}</Payload>
    </div>
  );

  return (
    <Paper className={isFullscreen ? 'fullscreen' : ''}>
      <div
        ref={ref}
        className="model_creation_text_field default-scrollbar"
        style={{
          padding: '8px',
          height: '250px',
          overflowY: 'scroll',
          opacity: 0.7,
          flex: 'auto',
        }}
      >
        {consoleMessagesReversed.map((message) => (
            <>
              {formatMessage(message)}
            </>
          )
        )}
      </div>
      <ButtonsRow>
        <Button onClick={reloadMessages}>
          Обновить
        </Button>
        <Button onClick={toggleFullscreen} style={{marginLeft: 'auto', minWidth: 'auto', width: '20px'}}>⤢</Button>
      </ButtonsRow>
    </Paper>
  )
}

const useScrollToBottomEffect = (consoleMessages) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [consoleMessages])

  return ref;
}

const useConsoleMessagesEffect = (stationId, authorization) => {
  const [messages, setMessages] = React.useState([]);

  const reloadMessages = React.useCallback(() => {
    getOcppMessages(stationId, {authorization})
      .then(response => response.json())
      .then((data) => {
        setMessages(data);
      });
  }, [stationId, authorization]);

  React.useEffect(() => {
    reloadMessages();
  }, []);

  return [messages, reloadMessages];
};

const useSendMessageEffect = (stationId, authorization) => {
  const [data, setData] = React.useState({
    action: '',
    payload: '',
  });

  const [error, setError] = React.useState('');
  const [response, setResponse] = React.useState('');

  const sendMessage = React.useCallback(() => {
    let payload = {};

    try {
      payload = JSON.parse(data.payload);
    } catch (e) {
      setError('Неверный JSON');
      return;
    }

    if (typeof payload !== 'object') {
      setError('Неверный JSON');
      return;
    }

     const action = data.action.trim();
    postStationMessage(stationId, {
      action,
      payload,
    }, {authorization})
      .then(response => response.text())
      .then((data) => {
        if (data.includes('error')) {
          setError(JSON.parse(data).error);
        } else {
          setResponse(data);
        }
      });
  }, [stationId, authorization, data]);

  const clearResponse = () => setResponse('');

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setError(false);
  }

  return {sendMessage, data, error, response, handleChange, clearResponse};
}

const mapStateToProps = ({manager}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  stationId: match.params.stationId,
});

export default connect(mapStateToProps)(StationConsoleTab);

export const CreatedAt = styled.span`
  color: #9acaff;
`;

export const Action = styled.span`
  color: inherit;
`;

export const Payload = styled.span`
  color: inherit;
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
