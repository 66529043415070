import React from 'react';

import {TopBarBlock, LeftSide, RightSide} from '../common/MainBlocks';
import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';

import SearchForm from './SearchForm';
import RefreshButton from "./RefreshButton";

const Topbar = () => {
  return (
    <TopBarBlock fixed white>
      <LeftSide>
        <TabLinkGroup>
          <TabLink>
            Все станции
          </TabLink>
        </TabLinkGroup>
      </LeftSide>
      <RightSide>
        <SearchForm/>
        <RefreshButton/>
      </RightSide>
    </TopBarBlock>
  );
};

export default Topbar;