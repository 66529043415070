import React from 'react';
import styled from 'styled-components';

import * as colors from '../../util/colors';
import {spin} from './animations';

const LoadingSpinner = ({size}) => (
  <Container viewBox="0 0 50 50" size={size}>
    <Circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </Container>
);

const Container = styled.svg`
  position: absolute;
  top: calc(50% - 4rem);
  left: calc(50% - 2.5rem);
  display: block;
  margin: 1rem auto;
  min-width: 5rem; max-width: 5rem;
  min-height: 5rem; max-height: 5rem;
  animation: ${spin(360)} 1s linear infinite;
  ${props => props.size && `transform: scale(${props.size});`}
  z-index: 1;
`;

export const Circle = styled.circle`
  stroke: ${colors.BLUE_MAIN};
  stroke-linecap: round;
  animation: dash 1s linear infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default LoadingSpinner;
