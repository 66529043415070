import React from "react";
import styled from "styled-components";

import EvseConnectorInfo from "./EvseConnectorInfo";
import EvseConnectorMonetize from "./EvseConnectorMonetize";
import * as colors from "../../util/colors";
import {FreeSpace} from "../common/FlexItem";
import ExpandIcon from "../common/ExpandIcon";
import changeStationStatus from "../../redux/actions/changeStationStatus";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const EvseConnectorSettings = ({ ...props }) => {
    props.charge_amount = '';
    props.charge_kwh = '';
    props.charge_hours = '';
    props.charge_minutes = '';

    return (
        <EvseConnectorSettingsStyled>

          <EvseConnectorInfo {...props} />

          {!props.isOperator && (<EvseConnectorMonetize {...props} />)}

        </EvseConnectorSettingsStyled>
    );
};

export const EvseSettingToggler = ({ onClick, isOpened, children}) => (
  <EvseSettingTogglerStyled onClick={onClick}>
    {children}{isOpened ? ':' : '..'}

    <FreeSpace>
      <ExpandIcon opened={isOpened}/>
    </FreeSpace>
  </EvseSettingTogglerStyled>
)

export const EvseSettingTogglerStyled = styled.div`
  display: flex;
  color: ${colors.TEXT_MAIN};
  cursor: pointer;
  font-size: 14px;
  margin-left: 16px;
`;

export const EvseConnectorSettingsStyled = styled.div`
  margin-left: 24px;
`;


const mapStateToProps = ({manager}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    isAdministrator: manager.data?.role === 'administrator',
    isManager: manager.data?.role === 'manager',
    isOperator: manager.data?.role === 'operator',
});

export default compose(connect(mapStateToProps))(EvseConnectorSettings);
