import React from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import {compose} from 'redux';

import {WrapperBlock, PageBlock, PageInnerBlock, TopBarBlock} from '../common/MainBlocks';
import Sidebar from '../common/Sidebar';
import TopbarBackButton from '../common/TopbarBackButton';
import OrderTabs from './OrderTabs';
import OrderInformationTab from './OrderInformationTab';
import OrderConsoleTab from "./OrderConsoleTab";
import TopbarMobile from "../common/TopbarMobile";

function Order(props) {
    const {orderId} = props.match.params;
    const {loggedIn, history, authorization, loading, isAdministrator, isOperator} = props;

    if (!loggedIn) {
        history.push('/');
    }

    if (!isAdministrator && !isOperator) {
        history.push('/');
    }

    return (
        <WrapperBlock>
            <TopbarMobile />
            <Sidebar/>
            <PageBlock>
                <PageInnerBlock>
                    {/* {error && error.message && <ErrorAlert>Something went wrong</ErrorAlert>} */}
                    <TopBarBlock white fixed>
                        <TopbarBackButton
                            to="/orders"
                            label="Зарядные сессии"
                        />
                    </TopBarBlock>

                    <OrderTabs
                        authorization={authorization}
                        orderId={orderId}
                        loading={loading}
                    />

                    <Route path="/orders/:orderId/information" component={OrderInformationTab}/>
                    <Route path="/orders/:orderId/console" component={OrderConsoleTab}/>
                </PageInnerBlock>
            </PageBlock>
        </WrapperBlock>
    );
}

const mapStateToProps = ({manager, order}) => ({
    loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    isAdministrator: manager.data?.role === 'administrator',
    isOperator: manager.data?.role === 'operator',
    ...order,
});

export default compose(
    connect(mapStateToProps),
    withRouter
)(Order);
