import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import * as colors from "../../util/colors";

import EvseConnectorStatus from "./EvseConnectorStatus";
import EvseConnectorInfoEditable from "./EvseConnectorInfoEditable";
import TitleField from "../common/TitleField";
import {EvseSettingToggler} from "./EvseConnectorSettings";

const EvseConnectorInfo = ({authorization, ...props}) => {
  const [isOpened, setOpened] = React.useState(false);
  const toggleOpened = () => setOpened(!isOpened);

  return (
    <>
      <StatusField {...props} />
      <br/>

        <div>
          <TitleField>Номер</TitleField>{props.number}
          <br/>

          <TitleField>Ток</TitleField>{props.current}
          <br/>

          <TitleField>Тип коннектора</TitleField>{props.type}
          <br/>

          <TitleField>Мощность, кВт/ч</TitleField>{props.power}
          <br/>

          <EvseConnectorInfoEditable {...props} />
          <br/>
        </div>
    </>
  );
};

const StatusField = (props) => {
  const [statusRelativeDate, statusFullDate] = getRelativeAndFullDatesWithServerOffset(props.status_updated_at, props.serverTimeOffset);

  return (
    <>
      <TitleField>Статус</TitleField>
      <EvseConnectorStatus status={props.status} male/>
      {` ${[props.origin_status, props.error_code].join(', ')} `}
      {props.info && `(${props.info})`}
      <EvseStatusTimestamp>
        {statusRelativeDate}
        {statusFullDate ? ` (${statusFullDate})` : ''}
      </EvseStatusTimestamp>
    </>
  )
}

export const EvseStatusTimestamp = styled.span`
  display: none;
  color: ${colors.MARKER_GRAY};
`;

const mapStateToProps = ({manager, app}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    isAdministrator: manager.data?.role === 'administrator',
    serverTimeOffset: app.serverTimeOffset,
});

export default connect(mapStateToProps)(EvseConnectorInfo);
