import React from "react";
import {postLocationPricing} from "../../api";
import styled from "styled-components";

import TitleField from "../common/TitleField";
import {SelectField, TextField} from "../common/Form";
import Button from "../common/Button";
import {connect} from "react-redux";
import {EvseSettingToggler} from "./EvseConnectorSettings";

const EvseConnectorMonetize = ({authorization, ...props}) => {
    const [formData, setFormData] = React.useState(props);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const [isOpened, setOpened] = React.useState(false);
    const toggleOpened = () => setOpened(!isOpened);

    const handleSave = useSavePricingEffect(props.location_id, formData, authorization);

    return (
        <>
            <EvseSettingToggler onClick={toggleOpened} isOpened={isOpened}>
                <EvseTitle>Тариф</EvseTitle>
            </EvseSettingToggler>

            {props.monetized && isOpened && (
                <EvseContent>
                    <EvseFrame>
                        <EvseConnectorTextField
                            label="Комиссия за начало зарядки, коп."
                            name="start_fee"
                            handleChange={handleChange}
                            {...formData}
                        />

                        <EvseConnectorSelectField
                            label="Тариф"
                            name="tariff"
                            options={[
                                // {id: 'minute', name: 'За минуту'},
                                {id: 'kwh', name: 'За кВтч'},
                            ]}
                            selectedOption={{id: 'kwh', name: 'За кВтч'}}
                            handleChange={handleChange}
                            {...formData}
                        />

                        <EvseConnectorTextField
                            label="Прайс, копеек за кВт"
                            name="rate"
                            handleChange={handleChange}
                            {...formData}
                        />
                        {props.editable && (
                            <Button
                                onClick={handleSave}
                                minWidth="131px"
                                maxWidth="none"
                                height="40px"
                                margin="0"
                            >
                                Изменить тариф
                            </Button>
                        )}

                    </EvseFrame>
                </EvseContent>
            )}

            {!props.monetized && isOpened && (
              <span style={{display: 'inline-block', verticalAlign: 'top', color:'coral', marginLeft: '16px'}}>Монетизация выключена!</span>
            )}
        </>
    );
};

const EvseConnectorTextField = (props) => {
    const value = props[props.name];

    return (
        <>
            <TitleField>{props.label}</TitleField>
            {!props.editable && (
                value
            )}
            {props.editable && (
                <TextField
                    name={props.name}
                    value={value}
                    width="150"
                    handleChange={props.handleChange}
                    disabled={props.name === 'start_fee'}
                    onClick={props.onClick}
                />
            )}
            <br/>
        </>
    )
}

const EvseConnectorSelectField = ({name, options, ...props}) => {
    const value = props[name];
    const findOptionByValue = value => options.find(option => option.id === value);
    const [selectedOption, selectOption] = React.useState(findOptionByValue(value) || options[0]);

    React.useEffect(() => {
        selectOption(findOptionByValue(value) || options[0])
    }, [options, value]);

    return (
        <div>
            <TitleField>{props.label}</TitleField>
            {!props.editable && (
                value
            )}
            {props.editable && (
                <SelectField
                    noDot
                    selectedFieldStyle={{border: '1px solid #CAD0D9'}}
                    optionHoverColor="#E1EEFF"
                    name={name}
                    width='150px'
                    selectedOption={selectedOption}
                    options={options}
                    handleChange={props.handleChange}
                />
            )}
        </div>
    )
}

const useSavePricingEffect = (location_id, formData, authorization) => React.useCallback(() => {
    const VALUES = ['location_id', 'type', 'current', 'power', 'tariff'];
    const INTEGERS = ['rate', 'start_fee'];
    const sendData = {};

    VALUES.forEach(key => sendData[key] = formData[key]);
    INTEGERS.forEach(key => sendData[key] = parseInt(formData[key]));

    postLocationPricing(location_id, sendData, {authorization})
        .then(response => response.text())
        .then(() => {
            window.location.reload();
        });
}, [location_id, formData, authorization])

const EvseFrame = styled.div`
    border: 10px solid #eff1f3;
    border-radius: 5px;
    padding: 10px 20px 10px 0;
    display: inline-block;
    margin: 10px 15px;
    button {
        margin-left: 266px;
    }
`;

const EvseContent = styled.div`
    margin-left: 20px;
`;

const EvseTitle = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: underline;
`;

const mapStateToProps = ({manager}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default connect(mapStateToProps)(EvseConnectorMonetize);
