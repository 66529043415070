import React, {Component} from 'react';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {loadPaymentsGroupedByLocationsInDay} from "./paymentsData";

import {Column, InfiniteLoader, Table, defaultTableRowRenderer} from 'react-virtualized';
import Paper from '../common/Paper';
import SearchForm from "../common/Form/SearchForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {MONTHS_INTERVAL} from "../Dashboard/StatisticsIntervals";
import StatisticsDates from "../Dashboard/StatisticsDates";
import LocationsFilter from "../common/Locations/LocationsFilter";
import {Header, RightSide, Container, Title, Price} from "../Dashboard/ChargingHistoryContainer";
import ExportExcel from "./ExportExcel";
import PaymentTypeFilter from "./PaymentTypeFilter";
import {
  capturedAmount,
  render4dColumn,
  renderFirstColumn,
  renderKwh,
  toCapturedAmount,
  toKWh
} from "./PaymentsHistoryColumns";

const defaultPaymentType = 'card';
const loadRowsLimit = 50000;

class PaymentsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      search: '',
      paymentsTotalCount: 0,

      expandedGroupIds: [],

      startDay: MONTHS_INTERVAL.startDay,
      startMonth: MONTHS_INTERVAL.startMonth,
      startYear: MONTHS_INTERVAL.startYear,

      endDay: MONTHS_INTERVAL.endDay,
      endMonth: MONTHS_INTERVAL.endMonth,
      endYear: MONTHS_INTERVAL.endYear,

      locationIds: [],
      paymentType: defaultPaymentType,
    };
  }

  componentDidMount() {
    this.loadMorePayments({startIndex: 0, stopIndex: loadRowsLimit});
  }

  getFromDate() {
    const {
      startDay,
      startMonth,
      startYear,
    } = this.state;

    const MM = moment().month(startMonth.id).format('MM');

    return moment(`${startDay.id}-${MM}-${startYear.id} 00:00:00`, 'DD-MM-YYYY');
  }

  getToDate() {
    const {
      endDay,
      endMonth,
      endYear
    } = this.state;

    const MM = moment().month(endMonth.id).format('MM');

    return moment(`${endDay.id}-${MM}-${endYear.id} 00:00:00`, 'DD-MM-YYYY').add(1, 'days');
  }

  batchLoadMorePaymentsOnScroll = async ({startIndex, stopIndex, search}) => {
    let lastKnown = startIndex;
    for (let i = stopIndex; i > startIndex; i--) {
      if (!this.state.orders[i]) { continue;}
      lastKnown = i;
      break;
    }
    const minBatchCount = 9;
    const batchCountToLoad = loadRowsLimit;
    // console.log(`batchLoadMoreOrdersOnScroll(${lastKnown}) count=${stopIndex - lastKnown}, ${startIndex} - ${stopIndex}`); // TODO: remove
    if (stopIndex - lastKnown >= minBatchCount) {
      // console.log(`   !!! loadMoreOrdersOnScroll(${lastKnown + 1} - ${lastKnown + 1 + batchCountToLoad})`); // TODO: remove
      // this.loadMorePayments({startIndex: lastKnown + 1, stopIndex: lastKnown + 1 + batchCountToLoad}); // TODO: uncomment and prevent multiple calls
    }
  };

  loadMorePayments = async ({startIndex, stopIndex, search}) => {
    const { payments, paymentsTotalCount } = await this.loadPayments({
      limit: stopIndex - startIndex + 1,
      offset: startIndex,
    });

    this.setState({
      paymentsTotalCount,
      orders: payments,
    });
  };

  setStateAndReload(state) {
    this.setState({...state, orders: []}, () => {
      this.loadMorePayments({startIndex: 0, stopIndex: loadRowsLimit});
    });
  }

  onSearchChange = (authorization, { search = '' } = {}) => {
    this.setState({
      search,
      orders: [],
    }, () => {
      this.loadMorePayments({startIndex: 0, stopIndex: loadRowsLimit});
    });
  }

  rowGetter = (value) => this.state.orders[value.index];

  rowLoaded = index => !!this.state.orders[index];

  // Max: 70. Min: 35.
  randomWidth = () => [(Math.floor(Math.random() * (70 - 35 + 1)) + 35), 'px'].join('');

  getPaymentsForExport = async () => {
    const {t} = this.props;
    const {payments} = await this.loadPayments({ limit: 10000 });

    let date;

    return payments.map(payment => {
      if (payment.title) {
        date = payment.title;

        return null;
      }

      return {
        'Дата': date,
        [t('locations')]: payment.location.name,
        [t('kWh')]: toKWh(payment),
        [`${t('chargingRevenue')}, ₽`]: toCapturedAmount(payment),
        'Кол-во заказов': payment.payments_count,
      }
    }).filter(payment => payment !== null);
  }

  loadPayments = (options) => {
    return loadPaymentsGroupedByLocationsInDay({
      from: this.getFromDate().toISOString(),
      to: this.getToDate().toISOString(),
      search: this.state.search,
      locationIds: this.state.locationIds?.join(',') || '',
      paymentTypesIds: this.state.paymentType,
      ...options,
    }, this.props.authorization);
  }

  onRowClick = ({ rowData }) => {
    if (!rowData.groupId) {
      return;
    }

    const { expandedGroupIds } = this.state;

    if (expandedGroupIds.includes(rowData.groupId)) {
      this.collapsePaymentGroup(rowData.groupId);

      this.setState({
        expandedGroupIds: expandedGroupIds.filter((groupId) => groupId !== rowData.groupId),
      })
    } else {
      this.expandPaymentGroup(rowData.groupId);

      this.setState({
        expandedGroupIds: [...expandedGroupIds, rowData.groupId],
      })
    }
  }

  expandPaymentGroup = (groupId) => {
    const orders = [...this.state.orders];
    const rowData = this.state.orders.find((rowData) => rowData.groupId === groupId);
    const index = this.state.orders.indexOf(rowData);

    orders.splice(index + 1, 0,  ...rowData.payments);

    this.setState({
      orders,
    })
  }

  collapsePaymentGroup = (groupId) => {
    this.setState({
      orders: this.state.orders.filter(({parentId}) => parentId !== groupId),
    })
  }

  rowRenderer = (props) => defaultTableRowRenderer({
    ...props,
    className: props.rowData.title ? `${props.className} titleRow` : props.className,
  });

  render() {
    const {props} = this;
    const {t} = props;

    return (
      <Paper padding="0">
        <Container>
          <Header>
            <Title>{t('chargingHistory')}</Title>

            <RightSide>
              <SearchForm onChange={this.onSearchChange} />
            </RightSide>
          </Header>

          <Header>
            <Title />

            <RightSide>
              <PaymentTypeFilter
                onChange={(paymentType) => this.setStateAndReload({ paymentType })}
                defaultType={defaultPaymentType}
              />
              <LocationsFilter onChange={(locationIds) => this.setStateAndReload({ locationIds })} />
              <ExportExcel getCsvData={this.getPaymentsForExport} fileName={`Export`} />
            </RightSide>
          </Header>

          <StatisticsDates
            data={this.state}
            handleChange={(state) => this.setStateAndReload(state)}
          />

          <InfiniteLoader
            isRowLoaded={this.rowLoaded}
            rowCount={Infinity}
            threshold={10}
            loadMoreRows={this.batchLoadMorePaymentsOnScroll}
          >
            {({onRowsRendered, registerChild}) => (
              <Table
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={52}
                headerHeight={52}
                width={760}
                height={500}
                rowClassName="ordersRow"
                rowGetter={this.rowGetter}
                rowCount={this.state.orders.length}
                onRowClick={this.onRowClick}
                rowRenderer={this.rowRenderer}
              >
                <Column width={330}
                  dataKey='location'
                  label={t('locations')}
                  style={{paddingLeft: '12px',paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => renderFirstColumn(rowData)}
                />
                <Column width={80}
                  dataKey='meter_stop'
                  label={t('kWh')}
                  style={{paddingLeft: '12px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => renderKwh(rowData)}
                />
                <Column width={150}
                  dataKey='captured_amount'
                  label={t('chargingRevenue')}
                  style={{paddingLeft: '12px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({cellData, rowData}) => capturedAmount(cellData, rowData)}
                />
                <Column width={200}
                  dataKey='payments_count'
                  label={'Кол-во заказов'}
                  style={{paddingLeft: '26px', paddingRight: '24px'}}
                  headerClassName="headerOrders"
                  cellRenderer={({rowData}) => render4dColumn(rowData)}
                />
              </Table>
            )}
          </InfiniteLoader>
        </Container>
      </Paper>
    );
  }
}

const mapStateToProps = ({ manager }) => ({
  isAdministrator: manager.data?.role === 'administrator',
});

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(PaymentsHistory);
