import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {DownloadItem, IntervalItem} from "../../Dashboard/StatisticsContainer.styled";
import Icon from "../Sidebar/SidebarIcon";
import StatisticsLocationsModal from "./LocationsModal";

function LocationsFilter({ onChange, data }) {
  const {t} = useTranslation();
  const [locationsModalVisible, setLocationsModalVisible] = React.useState(false);
  const [selectedLocationsCount, setSelectedLocationCount] = React.useState(0)

  const onLocationsInModalChange = (locationIds) => {
    setLocationsModalVisible(false);
    onChange(locationIds);
    setSelectedLocationCount(locationIds.length);
  }

  const allLocationsCount = data.length;
  const isAllLocationsSelected = allLocationsCount === selectedLocationsCount || selectedLocationsCount === 0;
  const selectedLocationsLabel = isAllLocationsSelected ? 'все' : `${selectedLocationsCount} из ${allLocationsCount}`;

  return (
    <>
      <IntervalItem onClick={() => setLocationsModalVisible(true)}>
        <DownloadItem>
          <span>{t('locations')} ({selectedLocationsLabel})</span>
          <Icon type={'mapMarkerActive'} />
        </DownloadItem>
      </IntervalItem>

      <StatisticsLocationsModal
        isOpen={locationsModalVisible}
        onCancelClick={() => setLocationsModalVisible(false)}
        onChnage={onLocationsInModalChange}
      />
    </>
  )
}

const mapStateToProps = ({locations: { data, loading }}) => ({
  data,
});

export default connect(mapStateToProps)(LocationsFilter);
