import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getStations from '../../redux/actions/getStations';
import LoadingSpinner from '../common/LoadingSpinner';
import Paper from '../common/Paper';
import {ErrorAlert} from '../common/Alerts';
import StationRow from './StationRow';

import {TableWrapper, Table, Thead, TheadTr, Th} from './StationsTable.styled';
import MiddleBar from "../Location/MiddleBar";


class StationsTable extends Component {
    offset = 0;
    initialLimit = 50;
    loadMoreIncrement = 50;

    constructor(props) {
        super(props);

        const {role} = this.props;

        this.state = {
            columns: [
                {name: 'id', label: 'Station ID', width: '300'},
                // {name: 'number', label: 'Номер', width: '50'},
                // {name: 'model', label: 'Модель', width: '100'},
                // {name: 'vendor_name', label: 'Производитель', width: '100'},
                {name: 'owner', label: 'Владелец', width: '90'},
                {name: 'location_name', label: 'Название локации', width: '250'},
                {name: 'status', label: 'Статус', width: '90'},
                {name: 'wifi', label: '', width: '24'},
                {name: 'monetized', label: '', width: '24'},
                {name: 'reservations_supported', label: '', width: '78'},
                {name: 'ping_timestamp', label: 'Связь со станцией', width: '90'},
                {name: 'context_menu', label: '', width: '24'},
            ],
            visibleOption: null,
        };

        if (role === 'manager') {
            this.state.columns = this.state.columns.filter(column => column.name !== 'owner');
        }
    }

    componentDidMount() {
        this.getStations();
        this.offset = this.initialLimit;
    }

    loadMore = () => {
        const {loadingMore, lastLoad} = this.props;

        if (!loadingMore && !lastLoad) {
            this.getStations({
                loadMore: true,
            });
            this.offset += this.loadMoreIncrement;
        }
    };

    toggleOptionVisibility = (id) => {
        this.setState({
            visibleOption: this.state.visibleOption === id ? null : id,
        })
    }

    getStations = ({loadMore = false, callback = null, ...options} = {}) => {
        const {
            authorization,
            params,
            getStations,
        } = this.props;

        getStations(
          authorization,
          {
              ...options,
              mode: 'full',
              limit: loadMore ? this.loadMoreIncrement : this.initialLimit,
              offset: loadMore ? this.offset : 0,
              search: (loadMore && params?.search) || '',
          },
          callback,
          loadMore,
        );
    }

    render() {
        const {columns} = this.state;
        const {serverTimeOffset, loading, loadingMore, lastLoad, data, error} = this.props;

        if (loading) {
            return (
                <div style={{flexGrow: 1}}>
                    <LoadingSpinner/>
                </div>
            );
        }

        if (error && error.message) {
            return <ErrorAlert>{error.message}</ErrorAlert>;
        }

        const allColumnsWidth = columns.reduce((accumulator, currentValue) => accumulator + +currentValue.width, 0);

        return (
            <>
                <Paper padding="0">
                    <TableWrapper style={{width: `${allColumnsWidth}px`}}>
                        <Table>
                            <colgroup>
                                {columns.map(col => <col key={col.name} width={col.width}/>)}
                            </colgroup>
                            <Thead>
                                <TheadTr>
                                    {columns.map(col => <Th key={col.name}>{col.label}</Th>)}
                                </TheadTr>
                            </Thead>
                            <tbody>
                            {data && data.map((station) =>
                                <StationRow
                                    key={station.id}
                                    serverTimeOffset={serverTimeOffset}
                                    visibleOption={this.state.visibleOption}
                                    toggleOptionVisibility={this.toggleOptionVisibility}
                                    {...station}
                                />
                            )}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Paper>

                {!loadingMore && !lastLoad && (
                  <MiddleBar
                    buttonText="Подгрузить еще 50"
                    buttonOnClick={this.loadMore}
                  />
                )}

                {loadingMore && (
                    <div style={{height: '100px', position: 'relative'}}>
                        <LoadingSpinner/>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({manager, stations: {data, params, loading, lastLoad, loadingMore, error}, app}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    serverTimeOffset: app.serverTimeOffset,
    data,
    params,
    loading,
    loadingMore,
    lastLoad,
    error,
    role: manager.data?.role,
});

const mapDispatchToProps = dispatch => ({
    getStations: (...params) => dispatch(getStations(...params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(StationsTable);
