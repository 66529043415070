import React, {Component} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import getManagerAccessToken from '../redux/actions/getManagerAccessToken';

import Home from './Home';
import LogIn from './LogIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import StatisticsByDay from './StatisticsByDay';
import StatisticsReports from './StatisticsReports';
import AddLocation from './AddLocation';
import ChangeTariffs from './Locations/ChangeTariffs';
import Locations from './Locations';
import Location from './Location';
import LocationsMap from './LocationsMap';
import AntiSanctionProtection from './AntiSanctionProtection';
import Station from './Station';
import Settings from './Settings';
import Terms from './Terms';
import Privacy from './Privacy';
import ChargePoints from './ChargePoints';
import Order from './Order';

import PasswordRecovery from './Home/PasswordRecovery';
import EmailVerification from './Home/EmailVerification';
import PaymentSuccess from './Payment/Success';
import PaymentFail from './Payment/Fail';
import DataDeletionRequest from './Home/DataDeletionRequest';
import AppManual from './Home/AppManual';

class App extends Component {
  componentDidMount() {
    const lastUpdated = new Date(this.props.accessTokenLastUpdatedAt);
    const now = new Date();
    const hoursPassed = Math.round((now.getTime() - lastUpdated.getTime()) / 36e5);
    if (hoursPassed >= 12) {
      const {email, refreshToken, getManagerAccessToken} = this.props;
      getManagerAccessToken(email, refreshToken);
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/statistics-by-day" component={StatisticsByDay} />
          <Route path="/statistics-reports" component={StatisticsReports} />
          <Route path="/locations/add" component={AddLocation} />
          <Route path="/locations/tariffs" component={ChangeTariffs} />
          <Route path="/locations/:locationId/" component={Location} />
          <Route path="/locations" component={Locations} />
          <Route path="/charge-points/:stationId/" component={Station} />
          <Route path="/orders/:orderId/" component={Order} />
          <Route path="/charge-points" component={ChargePoints} />
          <Route path="/map" component={LocationsMap} />
          <Route path="/anti-sanction-protection" component={AntiSanctionProtection} />
          <Route path="/settings" component={Settings} />
          <Route path="/auth/password-change/:token/" component={PasswordRecovery} />
          <Route path="/auth/verify-email/:token/" component={EmailVerification} />
          <Route exact path="/" component={Home} />
        </Switch>
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/payment/success" component={PaymentSuccess} />
        <Route exact path="/payment/fail" component={PaymentFail} />
        <Route exact path="/support/data-deletion-request" component={DataDeletionRequest} />
        <Route exact path="/support/app-manual" component={AppManual} />
      </Router>
    );
  }
}

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  email: manager.data && manager.data.email,
  refreshToken: manager.refreshToken,
  accessTokenLastUpdatedAt: manager.accessTokenLastUpdatedAt,
});

const mapDispatchToProps = dispatch => ({
  getManagerAccessToken: (email, refreshToken) =>
    dispatch(getManagerAccessToken(email, refreshToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
