import React from "react";
import {postStartCharging, postStopCharging} from "../../api";
import styled from "styled-components";

import TitleField from "../common/TitleField";
import {TextField} from "../common/Form";
import Button from "../common/Button";
import {connect} from "react-redux";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alerts/Alert";

const EvseConnectorInfoEditable = ({authorization, ...props}) => {
  const [formData, setFormData] = React.useState(props);

  const {
    loading,
    response,
    startConnectorHandler,
    stopConnectorHandler,
    clearResponse,
  } = useStartEffect(props.id, formData, authorization);

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
    if (name === 'charge_amount') {
      setFormData({
        ...formData,
        [name]: value,
        charge_kwh: formData.rate > 0 ? Math.floor(10 * value * 100 / formData.rate)/10 : 100,
        charge_hours: '',
        charge_minutes: '',
      });
    }
    if (name === 'charge_kwh') {
      setFormData({
        ...formData,
        [name]: value,
        charge_amount: Math.ceil(100 * value * formData.rate / 100) / 100,
        charge_hours: '',
        charge_minutes: '',
      });
    }
    if (name === 'charge_hours' || name === 'charge_minutes') {
      setFormData({
        ...formData,
        [name]: value,
        charge_amount: '',
        charge_kwh: '',
      });
    }
  }

  if (!props.editable) {
    return null;
  }

  return (
    <>
      {props.status === 'available' && (
        <EvseFrame>
          <EvseConnectorTextField
            label="Сумма зарядки, руб"
            name="charge_amount"
            handleChange={handleChange}
            onClick={(e)=>e.target.select()}
            {...formData}
          />
          <EvseConnectorTextField
            label="Энергия, кВтч"
            name="charge_kwh"
            handleChange={handleChange}
            onClick={(e)=>e.target.select()}
            {...formData}
          />
          <TitleField>Зарядка по времени, часы:мин.</TitleField>
          <TextField
            name="charge_hours"
            value={formData.charge_hours}
            width="50px"
            handleChange={handleChange}
            onClick={(e)=>e.target.select()}
          /> :
          <TextField
            name="charge_minutes"
            value={formData.charge_minutes}
            width="50px"
            handleChange={handleChange}
            onClick={(e)=>e.target.select()}
          />
          <Button
            onClick={startConnectorHandler}
            minWidth="131px"
            maxWidth="none"
            height="40px"
            margin="0"
          >Старт зарядки</Button>
        </EvseFrame>
      )}

      {props.status === 'busy' && (
        <EvseNoFrame>
          <Button
            onClick={stopConnectorHandler}
            minWidth="131px"
            maxWidth="none"
            height="40px"
            margin="0"
          >Отменить зарядку</Button>
        </EvseNoFrame>
      )}

      {loading && (
        <LoadingSpinner/>
      )}

      {response && (
        <Alert
          onClose={clearResponse}
          title={response}
        />
      )}
    </>
  );
}

const EvseConnectorTextField = (props) => {
    const value = props[props.name];

    return (
        <>
            <TitleField>{props.label}</TitleField>
            {!props.editable && (
                value
            )}
            {props.editable && (
                <TextField
                    name={props.name}
                    value={value}
                    width="150"
                    handleChange={props.handleChange}
                    disabled={props.name === 'start_fee'}
                    onClick={props.onClick}
                />
            )}
            <br/>
        </>
    )
}

const useStartEffect = (id, formData, authorization) => {
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState('');

    const startConnectorHandler = React.useCallback(() => {
        setLoading(true);
        const sendData = {
            limit_amount: parseFloat(formData.charge_amount) * 100 || undefined,
            limit_kwh: parseFloat(formData.charge_kwh) || undefined,
            limit_hours: parseInt(formData.charge_hours) || undefined,
            limit_minutes: parseInt(formData.charge_minutes) || undefined,
        };

        postStartCharging(id, sendData, {authorization})
            .then(response => response.text())
            .then(data => {
                let message = (data === 'Accepted') ? 'Запрос принят' : data;
                try {
                    const order = JSON.parse(data);
                    if (order && order.error) { message = `Ошибка: ${order.error}`; }
                    if (order && order.id) { message = `Запрос принят, ID: ${order.id}`; }
                } catch (e) {}
                setResponse(message);
                setLoading(false);
            });
    }, [id, formData, authorization]);

    const stopConnectorHandler = React.useCallback(() => {
        setLoading(true);
        postStopCharging(id, {authorization})
            .then(response => response.text())
            .then(data => {
                let message = (data === 'Accepted') ? 'Запрос принят' : data;
                try {
                    const order = JSON.parse(data);
                    if (order && order.error) { message = `Ошибка: ${order.error}`; }
                    if (order && order.id) { message = `Запрос принят, ID: ${order.id}`; }
                } catch (e) {}
                setResponse(message);
                setLoading(false);
            });
    }, [id, formData, authorization]);

    const clearResponse = () => {
        setResponse('');
        window.location.reload();
    };

    return {loading, response, clearResponse, startConnectorHandler, stopConnectorHandler}
};

const EvseFrame = styled.div`
    border: 10px solid #eff1f3;
    border-radius: 5px;
    padding: 10px 20px 10px 0;
    display: inline-block;
    margin: 10px 15px;
    button {
        margin-left: 266px;
    }
`;

const EvseNoFrame = styled.div`
    padding: 10px 20px 10px 0;
    display: inline-block;
    margin: 10px 15px;
    button {
        margin-left: 266px;
    }
`;

const mapStateToProps = ({manager}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default connect(mapStateToProps)(EvseConnectorInfoEditable);
