import React from 'react';

const RefreshIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="25px" height="25px">
      <path
        d="M 8 2 L 8 3 A 5 5 0 0 0 3 8 A 5 5 0 0 0 8 13 A 5 5 0 0 0 13 8 L 11 8 A 3 3 0 0 1 8 11 A 3 3 0 0 1 5 8 A 3 3 0 0 1 8 5 L 8 6 L 11 4 L 8 2 z "
        fill="#ffffff"
      />
    </svg>
  )
}

export default RefreshIcon;
