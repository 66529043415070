import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import getStation from '../../redux/actions/getStation';

import Paper from "../common/Paper";
import Evses from "../Location/Stations/Evses";

function StationEvsesTab(props) {
    const {stationId, authorization, data, getStation, isOperator} = props;

    React.useEffect(() => {
        getStation(authorization, stationId);
    }, [authorization, stationId]);

    if (!data?.id) {
        return null;
    }

    return (
        <Paper>
            <Evses
                monetized={data.monetized}
                evses={data.evses}
                stationId={stationId}
                locationId={data.location_id}
                full
                noTitle
                editable
            />
        </Paper>
    );
}

const mapStateToProps = ({manager, station}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    stationId: match.params.stationId,
    loading: station.stationsLoading,
    data: station.data,
    isOperator: manager.data?.role === 'operator',
});

const mapDispatchToProps = dispatch => ({
    getStation: (...params) => dispatch(getStation(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StationEvsesTab);
