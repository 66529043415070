import styled from "styled-components";
import * as colors from "../../../util/colors";
import ReactTooltip from "react-tooltip";
import React from "react";
import {connectorImageByType} from "../../Dashboard/ChargingHistoryContainer";

const EvseConnectorIcon = (props) => {
    const imageUrl = connectorImageByType(props.type);

    return (
        <EvseConnectorStyled status={props.status}>
            <EvseConnectorImage src={imageUrl}/>
            <div className="power">{`${props.type} (${props.power} kW)`}

                {props.rate > 0
                    ? <span style={{marginLeft: '16px'}}>{`${(props.rate/100).toFixed(2)}р/${props.tariff === 'kwh' ? 'кВтч' : props.tariff }`}</span>
                    : <span style={{marginLeft: '16px'}}>бесплатный</span>
                }

            </div>
            <ReactTooltip effect='solid' />
        </EvseConnectorStyled>
    );
};

const EvseConnectorStyled = styled.div`
  min-height: 35px; max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: ${props => {
    switch (props.status) {
        case 'available':
            return colors.ICON_GREEN;
        case 'busy':
            return colors.ICON_BLUE;
        case 'faulted':
            return colors.ERROR;
        case 'offline':
        default:
            return 'gray';
    }
}};
  &:first-child {
    padding-left: 3px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &:only-child {
    padding-left: 0;
    border-radius: 20px;
  }
  & div.power {
    font-size: 12px;
    color: white;
    padding: 0 5px;
  }
`;

const EvseConnectorImage = styled.img`
  min-width: 35px; max-width: 35px;
  min-height: 25px; max-height: 25px;
  filter: brightness(2);
  padding: 0 0 0 5px;
`;

export default EvseConnectorIcon;
