import React from 'react';
import {connect} from 'react-redux';
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import {getOwnerNameByData} from "../../util/owner";
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import getStation from '../../redux/actions/getStation';
import changeStationStatus from "../../redux/actions/changeStationStatus";
import changeMonetize from "../../redux/actions/changeMonetize";

import Paper from "../common/Paper";
import TitleField from "../common/TitleField";
import MiddleBar from "../Location/MiddleBar";
import {postResetStation} from "../../api";
import StationStatus from "../ChargePoints/StationStatus";
import {ToggleSwitch} from "../common/Form";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alerts/Alert";

function StationEvsesTab(props) {
    const {
        stationId,
        authorization,
        data,
        serverTimeOffset,
        getStation,
        changeStationStatus,
        changeMonetize,
        isAdministrator,
        isOperator,
    } = props;

    const {
        loading,
        response,
        resetServerStatusHandler,
        clearResponse,
    } = useResetServerEffect(stationId, authorization);

    React.useEffect(() => {
        getStation(authorization, stationId);
    }, [authorization, stationId]);

    if (!data?.id) {
        return null;
    }

    const {
        owner_first_name,
        owner_last_name,
        owner_email,
        evses,
        data_transfer,
    } = data;

    const owner_name = getOwnerNameByData(data);

    const [pingRelativeDate, pingFullDate] = getRelativeAndFullDatesWithServerOffset(data.ping_timestamp, serverTimeOffset);
    const [createdRelativeDate, createdFullDate] = getRelativeAndFullDatesWithServerOffset(data.created_at, serverTimeOffset);
    const [addedRelativeDate, addedFullDate] = getRelativeAndFullDatesWithServerOffset(data.added_at, serverTimeOffset);

    const isInoperative = evses.find(evse => evse.connectors.find(connector => !connector.operational));
    const isMonetized = data.monetized;

    const handleChangeStatus = (e) => {
        if (isOperator) { return; }
        const operational_status = e.target.checked ? 'operative' : 'inoperative';
        changeStationStatus(authorization, stationId, { operational_status, evses:[0] });
    };

    const handleChangeMonetize = (e) => {
        if (isOperator) { return; }
        const monetized = e.target.checked;
        changeMonetize(authorization, stationId, { monetized });
    };

    return (
        <>
            <Paper>
                <TitleField>ID станции</TitleField>{data.id}
                <img
                    src="/copyIcon.svg"
                    alt="copy icon"
                    className="icon-copy"
                    onClick={() => {navigator.clipboard.writeText(data.id)}}
                />
                <br/>

                <TitleField>Локация</TitleField>{data.location_name}<br/>
                <TitleField> &nbsp; </TitleField>
                {isAdministrator
                    ? <a className="address" style={{color: '#818286', fontSize: '0.875em'}} href={`/locations/${encodeURIComponent(data.location_id)}/stations`}>{data.location_city}, {data.location_street}</a>
                    : <a className="address">{data.location_city}, {data.location_street}</a>
                }<br/>


                <TitleField>Работоспособность коннекторов</TitleField>
                <ToggleField
                    value={!isInoperative}
                    handleChange={handleChangeStatus}
                    readonly={isOperator}
                />
                <br/>

                <TitleField>Статус</TitleField><StationStatus {...data} /><br/>

                <TitleField>Монетизация</TitleField>
                <ToggleField
                    value={isMonetized}
                    handleChange={handleChangeMonetize}
                    readonly={isOperator}
                /> {!data.monetized ? <span style={{display: 'inline-block', verticalAlign: 'top', color:'coral'}}>станция БЕСПЛАТНАЯ!</span> : ''}
                <br/>

                <TitleField>Порядковый номер на локации</TitleField>{data.number}<br/>
                <TitleField>Связь со станцией</TitleField>{pingRelativeDate} {pingFullDate ? `(${pingFullDate})` : ''}<br/>
                <br/>
                <TitleField>Модель</TitleField>{data.model}<br/>
                <TitleField>Производитель</TitleField>{data.vendor_name}<br/>
                {isAdministrator
                    ? <>
                        <TitleField>Серийный номер</TitleField>{data.serial_number}<br/>
                        <TitleField>ICCID</TitleField>{data.iccid}<br/>
                        <TitleField>IMSI</TitleField>{data.imsi}<br/>
                        <TitleField>Версия протокола</TitleField>{data.protocol}<br/>
                        <TitleField>Версия прошивки</TitleField>{data.firmware_version}<br/>
                        <TitleField>Владелец</TitleField>{owner_name}<br/>
                        <TitleField>Boot reason</TitleField>{data.boot_reason}<br/>
                    </>: ''}
                <TitleField>Резервирование</TitleField>
                <ToggleField value={data.reservations_supported} readonly={true} />
                <br/>

                <TitleField>Настроена</TitleField>
                <ToggleField value={data.configured} readonly={true} />
                <br/>

                {/*<TitleField>Создана</TitleField> {createdRelativeDate} ({createdFullDate})<br/>*/}
                <TitleField>Добавлена</TitleField> {addedRelativeDate} ({addedFullDate})<br/>

            </Paper>
            <MiddleBar
                buttonText="Перезагрузить станцию"
                buttonOnClick={() => resetServerStatusHandler()}
            />
            {loading && (
                <LoadingSpinner/>
            )}
            {response && (
                <Alert
                  onClose={clearResponse}
                  title={response}
                />
            )}
            {data_transfer && isAdministrator && (
              <DataTransfer data_transfer={data_transfer} />
            )}
        </>
    );
}

const DataTransfer = ({ data_transfer }) => (
  <Paper>
    <pre>
      {JSON.stringify(data_transfer, '', '  ')}
    </pre>
  </Paper>
)

export const ToggleField = ({style, ...props}) => (
    <div style={{display: 'inline-block', ...style}} onClick={(e) => e.stopPropagation()}>
        <ToggleSwitch valid={props.value} {...props} />
    </div>
)

const useResetServerEffect = (stationId, authorization) => {
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState('');

    const resetServerStatusHandler = React.useCallback(() => {
            setLoading(true);

            postResetStation(stationId, {authorization})
                .then(response => response.text())
                .then(data => {
                    setResponse(data === 'Accepted' ? 'Запрос принят' : data);
                    setLoading(false);
                })
        },[stationId, authorization]);

    const clearResponse = () => setResponse('');

    return {loading, response, clearResponse, resetServerStatusHandler}
}


const mapStateToProps = ({manager, station, app}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    stationId: match.params.stationId,
    loading: station.stationsLoading,
    data: station.data,
    serverTimeOffset: app.serverTimeOffset,
    isAdministrator: manager.data?.role === 'administrator',
    isOperator: manager.data?.role === 'operator',
});

const mapDispatchToProps = dispatch => ({
    getStation: (...params) => dispatch(getStation(...params)),
    changeStationStatus: (...params) => dispatch(changeStationStatus(...params)),
    changeMonetize: (...params) => dispatch(changeMonetize(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StationEvsesTab);
