import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import StatusBlock from "../common/StatusBlock";

const EvseConnectorStatus = ({male, status}) => (
    <StatusBlock status={status}>
        {
            {
                available: male ? 'Свободный' : 'Свободна',
                offline: male ? 'Отключен' : 'Отключена',
                faulted: male ? 'Неисправен' : 'Неисправна',
                busy: male ? 'Занят' : 'Занята'
            }[status]
        }
    </StatusBlock>
)

export default compose(withTranslation())(EvseConnectorStatus);
